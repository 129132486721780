<template>
    <div>
        <header class="header sticky" role="banner">
            <div class="wrap">

                <a class="logo"  href="/" id="top">&nbsp;</a>

                <nav role="navigation" class="main-nav">
                    <ul>
                        <li>
                            <button class="btn small color" v-if="numberOfArticles > 0" v-on:click="cartviewclicked">
                                <span class="d-none d-sm-inline">
                                    {{ numberOfArticles }} {{ store.locale.itemsLabel }} - {{ store.state.cart.pricing.subTotal.toCurrencyString() }}
                                </span> <i class="bi bi-basket"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'mf-header',	
        props: {
            store: Object,
            cartviewclicked: Function
        },
        computed: {
            numberOfArticles: function () {
                let articles = this.store.state.cart.articles;
                let articleCount = (articles || []).reduce(function (r, a) {
                    return r + (a.Amount || 0);
                }, 0);

                let blockCount = (this.store.state.cart.blocks || []).reduce(function (r, a) {
                    return r + (a.Amount || 0);
                }, 0);
                return articleCount + blockCount;
            }
        }
    };
</script>
