<template>
    <div class="autocomplete">
        <input type="text"
               v-on:input="onChange"
               v-model="search"
               v-on:keydown.down="onArrowDown"
               v-on:keydown.up="onArrowUp"
               v-on:keydown.enter="onEnter" />
        <ul id="autocomplete-results"
            v-if="items.length > 0 && !isLoading"
            v-show="isOpen"
            class="autocomplete-results">
            <li 
                v-for="(result, i) in results"
                :key="i"
                v-on:click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }">
                {{ result }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'mf-autocomplete',
        props: {
            items: {
                type: Array,
                required: false,
                default: () => [],
            },
            isAsync: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                isOpen: false,
                results: [],
                search: '',
                isLoading: false,
                arrowCounter: -1,
            };
        },
        watch: {
            items: function (value, oldValue) {
                if (value.length !== oldValue.length) {
                    this.results = value;
                    this.isLoading = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        },
        methods: {
            setResult(result) {
                this.search = result;
                this.isOpen = false;
                this.onChange();
            },
            filterResults() {
                this.results = this.items.filter((item) => {
                    return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                });
            },
            onChange() {
                this.$emit('input', this.search);

                if (this.isAsync) {
                    this.isLoading = true;
                } else {
                    this.filterResults();
                    this.isOpen = true;
                }
            },
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.search = this.results[this.arrowCounter];
                this.$emit('input', this.search);
                this.isOpen = false;
                this.arrowCounter = -1;
            },
        }
    };
</script>

<style>
    .autocomplete {
        position: relative;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #DFDFD0;
        max-height: 200px;
        overflow: auto;
        position: absolute;
        background: #fff;
        z-index: 99;
        color: #000;
        padding: 10px;
        width: 100%;
    }

    .autocomplete-result {
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
        list-style-type: none;
    }

        .autocomplete-result::before {
            content: '';
        }

        .autocomplete-result.is-active,
        .autocomplete-result:hover {
            background-color: #999999;
            color: white;
        }
</style>