// src/router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import ReservationTemplate from './pages/ReservationTemplate.vue';
import PaymentRequestTemplate from './pages/PaymentRequestTemplate.vue';
import ThankYouTemplate from './pages/ThankYouTemplate.vue';
import ExpiredTemplate from './pages/ExpiredTemplate.vue';
import FailedTemplate from './pages/FailedTemplate.vue';
import PaymentRedirectTemplate from './pages/PaymentRedirectTemplate.vue';
import CanceledTemplate from './pages/CanceledTemplate.vue';


Vue.use(VueRouter);

// Define your routes
const routes = [
  {
    path: '/',
    name: 'Reservation',
    component: ReservationTemplate
  },
  {
    path: '/paymentredirect/:id',
    name: 'PaymentRedirect',
    component: PaymentRedirectTemplate,
    props: true
  },
  {
    path: '/bedankt',
    name: 'Thank you',
    component: ThankYouTemplate
  },
  {
    path: '/verlopen',
    name: 'Expired',
    component: ExpiredTemplate
  },
  {
    path: '/mislukt',
    name: 'Failed',
    component: FailedTemplate
  },
  {
    path: '/geannuleerd',
    name: 'Canceled',
    component: CanceledTemplate
  },
  {
    path: '/betaalverzoek/:id/:token',
    name: 'PaymentRequest',
    component: PaymentRequestTemplate,
    props: true
  }
  
];

// Create the router instance
const router = new VueRouter({
  mode: 'history', // Enables cleaner URLs without hash (#)
  routes
});

export default router;
