@using Resources;

<template>

    <div>
        <div class="advanced-search grey"></div>
        <div class="wrap">
            <div class="row">
                <div class="col-lg-12">

                    <div v-if="step == 0">


                        <div v-if="cart.length > 0 && !store.state.tenant.HideReservationPeriodHeader">
                            <h5>{{ store.locale.products }}</h5>
                            <hr />
                            <strong>{{ store.locale.period }}:</strong> <br />
                            {{ store.locale.from }}: {{ store.state.departureDate.format("DD-MM-YYYY HH:mm") }}<br />
                            {{ store.locale.untill }}: {{ store.state.returnDate.format("DD-MM-YYYY HH:mm") }}<br />
                            {{ store.locale.numberOfDays }}: {{ store.state.returnDate.diff(store.state.departureDate,
                            'days') +1}}<br />
                            <br />

                            <mf-article-list :articles="cart" :store="store"></mf-article-list>

                            <div v-if="relatedArticles.length > 0">
                                <hr style="margin-top: 20px" />
                                <h5>{{ store.locale.suggestedForReservation }}</h5>
                                <hr />
                                <mf-article-list :articles="relatedArticles" :store="store"></mf-article-list>
                            </div>
                            <hr style="margin-top: 20px" />

                        </div>

                        <div v-if="store.state.cart.blocks.length > 0">
                            <h5>{{ store.locale.blockRental }}</h5>
                            <hr />

                            <table class="data data-reponsive">
                                <thead>
                                    <tr>
                                        <th>{{ store.locale.period }}</th>
                                        <th style="width:150px">{{ store.locale.amount }}</th>
                                        <th style="width:110px">{{ store.locale.price }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="block in store.state.cart.blocks"
                                        :key="block.Id + '_' + block.ArticleId">
                                        <td>
                                            <b>{{ block.FriendlyDate }} {{ store.locale.from }} {{ block.StartTime }} -
                                                {{ block.EndTime }} - {{ block.Title }}</b>
                                            <div class="help-block">{{ store.locale.amountAvailable }}: {{
                                                block.OriginalAmountAvailable-block.AmountRented }}</div>
                                        </td>
                                        <td class="text-center">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <button class="btn color" type="button"
                                                        v-on:click="block.Amount > 0 ? block.Amount -= parseInt(1) : block.Amount = 0; checkoverlap(block)">
                                                        <span class="bi bi-dash"></span>
                                                    </button>
                                                </div>
                                                <input type="number" class="form-control text-center p-0" disabled
                                                    min="0" :max="block.AmountAvailable" v-model="block.Amount"
                                                    placeholder="0">
                                                <div class="input-group-append">
                                                    <button class="btn color" type="button"
                                                        v-on:click="increaseAmount(block);">
                                                        <span class="bi bi-plus"></span>
                                                    </button>
                                                </div>
                                            </div>

                                        </td>
                                        <td class="text-right">
                                            {{ (block.Price * block.Amount).toCurrencyString() }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />
                        </div>

                        <div v-if="store.state.tenant.HideReservationPeriodHeader && cart.length > 0">
                            <h5>{{ store.locale.products }}</h5>
                            <hr />

                            <mf-article-list :articles="cart" :store="store"></mf-article-list>
                        </div>

                        <div v-if="pricing.SafeplanTotal > 0" :class="['safeplan-container', { 'safeplan-selected': isSafeplanSelected }]">
                            <label for="safeplan">
                                <img class="safeplan--image" src="@/assets/shield.svg" alt="SafePlan">
                                <div class="safeplan--label--container">
                                    <input id="safeplan" class="form-check" type="checkbox" name="safeplan" v-model="isSafeplanSelected"
                                        @change="updateTotal" />
                                    Het Safe Plan biedt gemoedsrust voor je gehuurde fiets. Met dit plan ben je verzekerd voor pechhulp, schade door diefstal en andere schades aan je fiets.
                                    <div class="prijs">
                                        <!-- {{ pricing.SafeplanTotal.toCurrencyString() }} -->
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div v-if="store.state.tenant.CouponsEnabled">
                            <div class="row">
                                <div class="col-lg-4"></div>
                                <div class="col-lg-8 text-right">
                                    <div class="form-group row" v-if="couponDiscount == 0">
                                        <label class="col-sm-5 col-form-label"><strong>{{ store.locale.coupon.question
                                                }}</strong></label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" v-model="couponCode"
                                                v-on:blur="VerifyCouponCode">
                                        </div>
                                        <div class="col-sm-3">
                                            <button class="btn btn-primary d-block"
                                                style="width:100%; padding:12px"><span class="d-none d-sm-inline">{{
                                                    store.locale.coupon.validate }}</span>
                                                <i class="bi bi-check"></i></button>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-sm-12 text-right" v-if="couponDiscount > 0">
                                            <strong>{{ store.locale.coupon.yourcode }}:</strong> {{
                                            couponCode.toUpperCase() }} <i class="bi bi-check"></i> : {{ couponDiscount
                                            }}%
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <hr />
                        </div>




                        <div class="row" v-if="pricing.subTotal != total">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">{{ store.locale.subTotal }}</div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">{{ pricing.subTotal.toCurrencyString() }}
                            </div>
                        </div>

                        <div class="row" v-if="pricing.reservationCosts > 0">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">{{ store.locale.reservationCosts }}
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">{{
                                pricing.reservationCosts.toCurrencyString()}}</div>
                        </div>

                        <div class="row" v-if="discount > 0">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">{{ store.locale.discount }}</div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">- {{ discount.toCurrencyString() }}
                            </div>
                        </div>

                        <div class="row" v-if="pricing.deposit > 0">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">{{ store.locale.deposit }}</div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">{{ pricing.deposit.toCurrencyString() }}
                            </div>
                        </div>

                        <div class="row" v-if="isSafeplanSelected">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">SafePlan</div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">{{ pricing.SafeplanTotal.toCurrencyString() }}
                            </div>
                        </div>

                      

                        <div class="row" v-if="total > 0">
                            <div class="col-lg-10 col-md-8 col-sm-6 text-right">
                                <h2>{{ store.locale.total }}</h2>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-6 text-right">
                                <h2>{{ (total - discount).toCurrencyString() }}</h2>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-6">
                                <button class="btn medium" v-on:click="closecart"><i class="bi bi-arrow-left"></i> <span
                                        class="d-none d-sm-inline">{{ store.locale.continueShopping }}</span></button>
                            </div>
                            <div class="col-6">
                                <button class="btn medium color right" v-on:click="store.gotoTop(); step = 1;"
                                    v-if="step == 0"><span class="d-none d-sm-inline">{{ store.locale.advanceToDetails
                                        }}</span> <i class="bi bi-arrow-right"></i></button><br />
                            </div>
                        </div>

                        <div class="row d-block d-sm-none m-2">
                            <div class="col-12 text-right"><i>{{ store.locale.advanceToDetails }}</i></div>

                            <div v-if="percentageToPay != 100">
                                {{ percentageToPay }}% {{ store.locale.amountToPay }}
                            </div>

                        </div>

                    </div>

                    <mf-userdetails v-else @onback="store.gotoTop(); step = 0" @formvalidated="createReservation"
                        :total="total" :discount="discount" :cart="store.state.cart" :store="store"
                        :percentage-to-pay="percentageToPay" :placeofstay="store.defaultPlaceOfStayDescription()">
                    </mf-userdetails>

                </div>


            </div>


        </div>
    </div>

</template>

<script>
import ArticleTable from './ArticleTable.vue';
import UserDetails from './UserDetails.vue';
import { Tooltip } from 'bootstrap';
import axios from 'axios';
import moment from 'moment';

export default {
    props: ['articles', 'store', 'percentageToPay', 'pricing', 'departureDate', 'returnDate', 'advanceToDetails', 'closecart'],
    components: {
        'mf-article-list': ArticleTable,
        'mf-userdetails': UserDetails
    },
    data: function () {
        return {
            step: 0,
            cart: this.articles,
            couponCode: '',
            couponDiscount: 0,
            isSafeplanSelected: false,
        }
    },
    mounted() {
    // Initialize the tooltip
    new Tooltip(this.$refs.tooltipButton);
    },
    computed: {
        discount: function () {

            if (this.couponDiscount > 0) {
                return Number(((this.pricing.subTotal - this.pricing.extraDiscount) / 100 * this.couponDiscount) + this.pricing.extraDiscount);
            }

            if (!this.store.state.hasDiscountAvailableOnDay) {
                return 0;
            }

            // eslint-disable-next-line
            if (!this.pricing.extraDiscount) {
                // eslint-disable-next-line
                this.pricing.extraDiscount = 0;
            }

            let discountPercentage = this.store.state.tenant.WebsiteDiscount !== '' ? Number(this.store.state.tenant.WebsiteDiscount) : 0;
            return Number(((this.pricing.subTotal - this.pricing.extraDiscount) / 100 * discountPercentage) + this.pricing.extraDiscount);
        },
        total: function () {

            return (this.pricing.subTotal + this.pricing.deposit + Number(this.pricing.reservationCosts) +
            (this.isSafeplanSelected ? this.pricing.SafeplanTotal : 0));
        },
        relatedArticles: function () {
            let related = [];
            this.cart.map(x => {
                x.Related.map(y => {
                    if (related.filter(x => x.Id == y.Id).length == 0) {
                        related.push(y)
                    }
                })[0]
            });
            return related;
        },
        isSafeplanChecked() {
            return this.pricing.SafeplanTotal > 0;
        },
    },
    methods: {
        VerifyCouponCode() {
            this.couponDiscount = 0;
            if (this.couponCode.length > 3) {
                axios.get(`api/couponcode/verify?code=${this.couponCode}`).then(response => {
                    if (response.data != "0") {
                        this.couponDiscount = parseFloat(response.data).toFixed(2);
                    }
                }).catch(error => {
                    console.error("There was an error verifying the coupon code:", error);
                });
            }
        },
        increaseAmount(block) {
            if (block.OriginalAmountAvailable - block.AmountRented <= 0) {
                return;
            }

            block.Amount < block.AmountAvailable ? block.Amount += parseInt(1) : block.Amount = block.AmountAvailable;
            this.checkoverlap(block);

        },
        checkoverlap(block) {
            this.store.state.cart.blocks.filter(x => x.Date == block.Date).map(bl => {
                //bl.AmountAvailable = Number(bl.OriginalAmountAvailable);
                bl.AmountRented = this.amountRentedAtPeriod(bl);
                if (bl.AmountRented > bl.AmountAvailable) {
                    bl.AmountRented = bl.AmountAvailable;
                }
            });
        },
        amountRentedAtPeriod(block) {
            let existing = this.store.state.cart.blocks.filter(x => x.Date == block.Date);
            let newBlockStart = moment(block.Date + "T" + block.StartTime);
            let newBlockEnd = moment(block.Date + "T" + block.EndTime);
            let amountRented = 0;
            existing.forEach(period => {
                let existingStart = moment(period.Date + "T" + period.StartTime);
                let existingEnd = moment(period.Date + "T" + period.EndTime);
                if (existingStart.isBefore(newBlockEnd) && existingEnd.isAfter(newBlockStart)) {
                    amountRented += period.Amount;
                }
            });
            return amountRented;
        },
        createReservation: function (person) {

            var selectedArticles = [];

            this.store.state.cart.articles.map(article => {
                selectedArticles.push({ Amount: article.Amount, Id: article.Id, UseSafeplan: this.isSafeplanSelected });
            });

            this.store.getRelatedArticlesCart(this.store.state.cart.articles).map(article => {
                selectedArticles.push({ Amount: article.Amount, Id: article.Id });
            });

            let blocks = [];
            this.store.getBlocksInCart().map(blockArticle => {
                let start = blockArticle.Date + 'T' + blockArticle.StartTime;
                let end = blockArticle.Date + 'T' + blockArticle.EndTime;
                blocks.push({ Amount: blockArticle.Amount, Id: blockArticle.ArticleId, StartDate: start, ReturnDate: end });
            });

            var request = {
                Contact: {
                    Salutation: person.salutation,
                    Name: person.name,
                    FirstName: person.firstName,
                    PhoneNumber: person.phonenumber,
                    EmailAddress: person.emailaddress,
                    Address: {
                        Street: person.address,
                        StreetSuffix: person.streetSuffix,
                        StreetNumber: person.streetNumber,
                        PostalZipCode: person.postalZipCode,
                        City: person.city,
                        Country: person.country,
                    }
                },
                Location: person.placeOfStay,
                LocationSpecified: person.locationSpecified,
                PickupLocation: person.pickupLocation ? "DELIVERY" : this.store.state.selectedLocation.Name,
                StartDate: this.store.state.departureDate ? this.store.state.departureDate.format("YYYY-MM-DDTHH:mm") : null,
                ReturnDate: this.store.state.departureDate ? this.store.state.returnDate.format("YYYY-MM-DDTHH:mm") : null,
                Articles: selectedArticles,
                Blocks: blocks,
                Remark: person.remark,
                Language: this.store.locale.locale,
                LocationId: this.store.state.selectedLocation.Id,
                CouponCode: this.couponCode,
                CompanyName: person.companyName,
                CompanyVatNumber: person.companyVatNumber,
                AdditionalContact: {
                    FirstName: person.additionalAddress.firstName,
                    Name: person.additionalAddress.name,
                    Address: {
                        Street: person.additionalAddress.street,
                        StreetSuffix: person.additionalAddress.streetSuffix,
                        StreetNumber: person.additionalAddress.streetNumber,
                        PostalZipCode: person.additionalAddress.postalZipCode,
                        City: person.additionalAddress.city,
                        Country: person.additionalAddress.country,
                    }
                }

            };

            axios.post("/api/reservation/create", request)
                .then(response => {
                    const result = response.data;

                    if (!isNaN(result)) {
                        this.$refs.backButton.click();
                        this.$refs.payButton.textContent = this.store.locale.btnToPayment;
                        this.$refs.payButton.removeAttribute("disabled");
                        this.$refs.payButton.classList.add("color");

                        setTimeout(function () {
                            alert("@Resources.MsgMinimumDurationInPeriod " + -result);
                            document.getElementById("ret-date").focus();
                        }, 500);

                    } else {
                        window.location.href = result;
                    }
                })
                .catch(error => {
                    console.error("There was an error creating the reservation:", error);
                });
        },
        updateTotal(event) {
            this.isSafeplanSelected = event.target.checked;
        }
    }
};
</script>