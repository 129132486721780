<template>
    <div>
        <div class="row d-block d-sm-none">

            <div class="col-sm-12" v-for="(article, index) in articleList" :key="`${index}_article`">

                <div class="card">

                    <div class="card-body">


                        <div class="featured-image">
                            <img loading="lazy" :src="'https://api.mijnfietsverhuur.nl/file/get?reference=' + article.Image" />
                            <br />
                        </div>

                        <div class="card-title mt-2">
                            <h3>{{ store.getArticleTitle(article) }}</h3>
                        </div>

                        <p>{{ store.getArticleDescription(article) }}</p>
                        <div v-if="article.FixedPrice > 0" class="help-block mb-2">{{ store.locale.price }}: {{
                            article.FixedPrice.toCurrencyString() }}</div>

                        <div v-if="article.Blocks == null || article.Blocks.length == 0">

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button class="btn color" type="button"
                                        v-on:click="article.Amount > 0 ? article.Amount -= parseInt(1) : article.Amount = 0; amountChanged(article)">
                                        <span class="bi bi-dash"></span>
                                    </button>
                                </div>
                                <input type="number" class="form-control text-center p-0" disabled min="0" max="200"
                                    placeholder="0" v-model="article.Amount">
                                <div class="input-group-append">
                                    <button class="btn color" type="button"
                                        v-on:click="article.Amount += parseInt(1); amountChanged(article)">
                                        <span class="bi bi-plus"></span>
                                    </button>
                                </div>
                            </div>


                            <div class="text-center" style="margin: 15px">
                                <h3>{{ store.getArticlePrice(article).toCurrencyString() }}</h3>
                            </div>
                        </div>


                        <div v-else class="text-center p-3">
                            <button v-if="article.Blocks !== null && article.Blocks.length > 0" class="btn color"
                                v-on:click="enlargeAvailabilty(article)">{{ store.locale.reserveBlock }}</button>

                        </div>


                    </div>
                </div>
                <br />
            </div>
        </div>
        <div class="d-none d-sm-block">

            <div class="alert alert-warning text-center" v-if="articleList.length === 0">
                <strong>{{ store.locale.warningArticlesUnAvailable }}</strong>
            </div>
            <table class="data data-reponsive" v-if="articleList.length > 0">
                <thead>
                    <tr>
                        <th style="width: 120px">{{ store.locale.articleType }}</th>
                        <th></th>
                        <th style="width:150px">{{ store.locale.amount }}</th>
                        <th style="width:110px">{{ store.locale.price }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="article in articleList" :key="article.Id">
                        <td class="text-center">
                            <div class="featured-image" v-if="article.Image" v-on:click="enlargeImage(article)">
                                <img loading="lazy" :src="'https://api.mijnfietsverhuur.nl/file/get?reference=' + article.Image" style="width: 80px" />
                            </div>
                        </td>
                        <td>
                            <strong>{{ store.getArticleTitle(article) }}</strong><br />
                            <p>{{ store.getArticleDescription(article) }}</p>

                            <div v-if="article.Composed.length > 0">
                                {{ store.locale.inclusiveComposedFromArticles }}:<br />
                                <div v-for="(composedArticle, index) in article.Composed"
                                    :key="`composedfrom-${article.Id}-${index}`" class="badge badge-info"
                                    style="margin-right: 4px">{{ store.getArticleTitle(composedArticle) }}</div>
                            </div>

                            <div v-if="article.FixedPrice > 0" class="help-block">{{
                                article.FixedPrice.toCurrencyString() }}</div>
                        </td>
                        <td class="text-center">
                            <div class="input-group" v-if="article.Blocks == null || article.Blocks.length == 0">
                                <div class="input-group-prepend">
                                    <button class="btn color" type="button"
                                        v-on:click="article.Amount > 0 ? article.Amount -= parseInt(1) : article.Amount = 0; amountChanged(article)">
                                        <span class="bi bi-dash"></span>
                                    </button>
                                </div>
                                <input type="number" class="form-control text-center p-0" disabled min="0" max="200"
                                    placeholder="0" v-model="article.Amount">
                                <div class="input-group-append">
                                    <button class="btn color" type="button"
                                        v-on:click="article.Amount += parseInt(1); amountChanged(article)">
                                        <span class="bi bi-plus"></span>
                                    </button>
                                </div>

                            </div>
                            <button v-if="article.Blocks !== null && article.Blocks.length > 0" class="btn color"
                                v-on:click="enlargeAvailabilty(article)">{{ store.locale.reserveBlock }}</button>

                        </td>
                        <td :class="`pricetag-${article.Id} text-right`">
                            <span v-if="article.Amount > 0">
                                {{ store.getArticlePrice(article).toCurrencyString() }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    

        <mf-modal v-if="showEnlargeModal" @close="showEnlargeModal = false">
            <h1 slot="header" class="text-center">{{ store.getArticleTitle(detailArticle) }}</h1>
            <img slot="body" loading="lazy" :src="'https://api.mijnfietsverhuur.nl/file/get?reference=' + detailArticle.Image" />
            <div slot="footer" class="row">
                <div class="col-lg-6">
                    <table class="table borderless"
                        v-if="detailArticle.Blocks === null || detailArticle.Blocks.length == 0">
                        <tr>
                            <td class="text-center"><strong>{{ store.locale.amount }}</strong></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button class="btn color" type="button"
                                            v-on:click="detailArticle.Amount > 0 ? detailArticle.Amount -= parseInt(1) : detailArticle.Amount = 0; amountChanged(detailArticle)">
                                            <span class="bi bi-dash"></span>
                                        </button>
                                    </div>
                                    <input type="number" class="form-control text-center p-0" disabled min="0" max="200"
                                        placeholder="0" v-model="detailArticle.Amount">
                                    <div class="input-group-append">
                                        <button class="btn color" type="button"
                                            v-on:click="detailArticle.Amount += parseInt(1); amountChanged(detailArticle)">
                                            <span class="bi bi-plus"></span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td style="width:150px; padding:20px; text-align: right">
                                {{ store.getArticlePrice(detailArticle).toCurrencyString() }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-lg-6">
                </div>

                <div class="col-lg-12 text-right">
                    <button class="btn color medium" v-on:click="showEnlargeModal = false">{{ store.locale.close }} <i
                            class="bi bi-x-circle"></i></button>
                </div>
            </div>
        </mf-modal>

        <mf-modal v-if="showAvailabilty" @close="showAvailabilty = false">

            <div slot="header">
                <h1>{{ detailArticle.Title }}</h1>
            </div>

            <div slot="body">
                <div class="form-group">
                    <label for="aankomst_datum">{{ store.locale.chooseDate }}</label>
                    <mf-datepicker :initial-blank="true" :disabled-dates="blockedDates"
                        :min-date="minDate" :datechanged="setSelectedBlockDate" />
                </div>

                <div class="row" v-if="onDepartureMessage">
                    <div class="col-lg-12">
                        <div class="alert alert-io">
                            {{ onDepartureMessage }}
                        </div>
                    </div>
                </div>

                <div class="row" v-if="onReturnMessage">
                    <div class="col-lg-12">
                        <div class="alert alert-danger">
                            {{ onReturnMessage }}
                        </div>
                    </div>
                </div>

                <div class="row" v-if="selectedDate != null && blockDateSelected">
                    <div class="col">
                        <div class="mt-2 card text-white bg-dark d-none d-sm-block">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8 text-center">
                                        {{ store.locale.period }}
                                    </div>
                                    <div class="col-md-2 text-center">
                                        {{ store.locale.amount }}
                                    </div>
                                    <div class="col-md-2 text-center">
                                        {{ store.locale.price }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 card text-dark">
                            <div class="card-body">

                                <mf-spinner v-if="isLoading"></mf-spinner>

                                <div class="well well-sm text-center" v-if="blocks.length === 0 && !isLoading">
                                    {{ store.locale.dayUnavailable }}
                                </div>
                                <div class="row" v-for="(block, index) in filteredBlocks" :key="'block-' + index"
                                    style="border-bottom: 1px #efefef solid; margin-bottom: 8px">
                                    <div class="col-md-8">
                                        <b>{{ block.StartTime }} - {{ block.EndTime }}</b>
                                        <div class="help-block">
                                            {{ store.locale.amountAvailable }}: {{ block.OriginalAmountAvailable <= 0 ?
                                                0 : block.OriginalAmountAvailable - block.AmountRented }} </div>
                                        </div>
                                        <div class="col-md-2 text-center">
                                            <div class="input-group" v-if="block.AmountAvailable > 0">
                                                <div class="input-group-prepend">
                                                    <button class="btn color" type="button"
                                                        v-on:click="block.Amount > 0 ? block.Amount -= parseInt(1) : block.Amount = 0; checkoverlap(block)">
                                                        <span class="bi bi-dash"></span>
                                                    </button>
                                                </div>
                                                <input type="number" class="form-control text-center p-0" disabled min="0"
                                                    :max="block.AmountAvailable" v-model="block.Amount" placeholder="0">
                                                <div class="input-group-append">
                                                    <button class="btn color" type="button"
                                                        v-on:click="increaseAmount(block)">
                                                        <span class="bi bi-plus"></span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-2 text-right">
                                            <span v-if="block.AmountAvailable > 0">
                                                {{ (block.Price * block.Amount).toCurrencyString() }}
                                            </span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>


                    <div class="stickied-footer" v-if="hasBlocksSelected">
                        <div class="row">
                            <div class="col-lg-12 text-right">
                                <button class="btn btn-success medium" v-on:click="addSelectedBlocks">{{
                                    store.locale.addToBasket }} <i class="bi bi-cart-plus"></i></button>
                            </div>
                        </div>
                    </div>

                </div>

        </mf-modal>
    </div>
</template>

<style>

.mx-datepicker-popup {
        z-index: 1050 !important; /* Zorg ervoor dat de datepicker boven de modal wordt weergegeven */
    }

.stickied-footer .btn {
    width: 100%;
    margin-top: 12px;
}

.stickied-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: white;
    text-align: center;
    z-index: 99;
    padding: 20px;
    -webkit-box-shadow: 0px -7px 45px -19px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px -7px 45px -19px rgba(0, 0, 0, 0.48);
    box-shadow: 0px -7px 45px -19px rgba(0, 0, 0, 0.48);
}

@media only screen and (min-width: 768px) {
    .stickied-footer {
        position: relative;
        -webkit-box-shadow: 0px -7px 20px -19px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: 0px -7px 20px -19px rgba(0, 0, 0, 0.48);
        box-shadow: 0px -7px 20px -19px rgba(0, 0, 0, 0.48);
    }
}
</style>
<script>
import Vue from 'vue';
import DatePicker from './DatePicker.vue';
import Modal from './Modal.vue';
import Spinner from './Spinner.vue';
import moment from 'moment';
import axios from 'axios';

export default {
    props: ['store', 'articles'],
    components: {
        'mf-datepicker': DatePicker,
        'mf-spinner': Spinner,
        'mf-modal': Modal
    },
    data: function () {
        return {
            scrollY: 0,
            isLoading:
                false, amountReservedOnDay: 0, detailArticle: null, showEnlargeModal: false,
            showAvailabilty: false, infoArticle: null, state: this.store.state, blocks: [],
            minDate: moment().format('YYYY-MM-DD'), selectedDate: null, blockDateSelected:
                false, onDepartureMessage: "", onReturnMessage: ""
        }
    },
    mounted: function () {
        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    },

    watch: {
        selectedDate() {
            this.fetchBlockAvailability();
        },
        showAvailabilty() {
            this.blockDateSelected = false;
            if (this.showAvailabilty) {
                this.selectedDate = this.store.state.departureDate ?
                    this.store.state.departureDate.clone().format('YYYY-MM-DD') : null;
                this.blocks = [];
                this.scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                const body = document.body;
                body.style.position = 'fixed';

            } else {
                this.selectedDate = null;
                const body = document.body;
                body.style.position = '';
                body.style.top = '';
                window.setTimeout(() => {
                    window.scrollTo(0, parseInt(this.scrollY || '0') * 1);
                }, 50);
            }
        }
    },
    computed: {
        hasBlocksSelected() {
            return (this.blocks || []).reduce(function (r, a) {
                return r + (a.Amount || 0);
            }, 0) > 0;
        },
        filteredBlocks: function () {
            var sorted = [...this.blocks].sort((a, b) => a.DayOfWeek - b.DayOfWeek ||
                Number(a.StartTime.replace(':', '')) - Number(b.StartTime.replace(':', '')))
            return sorted;
        },
        articleList: function () {
            if (!this.articles)
                return [];

            let articles = this.articles.filter(article =>
                !this.state.selectedLocation.BlockedArticles.includes(article.Id));

            if (this.state.tenant.HideReservationPeriodHeader) {
                articles = articles.filter(article => article.Blocks != null && article.Blocks.length >
                    0 || article.FixedPrice > 0);
            }

            return articles;

        },
        blockedDates() {

            const workingHoursSorted = [...this.state.workinghours].sort((a, b) =>
                moment(a.DateFrom, 'YYYY-MM-DD').diff(moment(b.DateFrom, 'YYYY-MM-DD')));

            const getDatesOfDayOfWeekInPeriod = (start, end, dayOfWeek) => {
                var result = [];
                var current = start.clone();

                while (current.isSameOrBefore(end)) {
                    if (current.day() === dayOfWeek) {
                        result.push(current.clone().format('YYYY-MM-DDT00:00'));
                    }
                    current.add(1, 'day');
                }

                return result;
            };

            let blockedWorkingHours = [];
            workingHoursSorted.map(w => {

                let currentScheduleStart = moment(w.DateFrom, 'YYYY-MM-DD').startOf('day');
                let nextScheduleStart = workingHoursSorted.filter(x => moment(x.DateFrom,
                    'YYYY-MM-DD').isAfter(currentScheduleStart));

                let checkStartDay = moment();

                if (currentScheduleStart.isBefore(checkStartDay)) {
                    currentScheduleStart = checkStartDay;
                }

                let checkUntillDay = currentScheduleStart.clone().add(10, 'months');
                nextScheduleStart = nextScheduleStart.length > 0 ? moment(nextScheduleStart[0].DateFrom,
                    'YYYY-MM-DD').startOf('day') : checkUntillDay;

                if (nextScheduleStart.isAfter(checkUntillDay)) {
                    nextScheduleStart = checkUntillDay;
                }

                for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                    const
                        schedule = w.Schedule.filter(y => y.DayOfWeek == dayOfWeek);
                    if (schedule.length == 0) {
                        // no day in this period, so closed
                        // fetch all dates from the start of this period untill the start of the next period
                        let days = getDatesOfDayOfWeekInPeriod(currentScheduleStart.add(-1, 'days'),
                            nextScheduleStart, dayOfWeek);
                        blockedWorkingHours.push(days);
                    }
                }

            });

            return this.state.selectedLocation.BlockedDates.concat(...blockedWorkingHours);
        }
    },
    methods: {
        setSelectedBlockDate(dt) {
            this.selectedDate = moment(dt).format('YYYY-MM-DD');
            this.blockDateSelected = true;
        },
        noblocksAvailableMessage() {

            let dt = moment(this.selectedDate);
            let departureDayRemark = this.store.getStartDayMessage(dt);

            this.onDepartureMessage = departureDayRemark;

        },

        increaseAmount(block) {
            if (block.OriginalAmountAvailable - block.AmountRented <= 0) { return; }
            block.Amount < block.AmountAvailable ? block.Amount += parseInt(1) :
                block.Amount = block.AmountAvailable; this.checkoverlap(block);
        }, checkoverlap() {
            this.blocks.filter(x => x.Date == this.selectedDate).map(bl => {
                //bl.AmountAvailable = Number(bl.OriginalAmountAvailable);
                bl.AmountRented = this.amountRentedAtPeriod(bl);
                if (bl.AmountRented > bl.AmountAvailable) {
                    bl.AmountRented = bl.AmountAvailable;
                }
            });
        },

        amountRentedAtPeriod(block) {
            let existing = this.blocks.filter(x => x.Date == this.selectedDate);
            let newBlockStart = moment(block.Date + "T" + block.StartTime);
            let newBlockEnd = moment(block.Date + "T" + block.EndTime);
            let amountRented = 0;
            existing.forEach(period => {
                let existingStart = moment(period.Date + "T" + period.StartTime);
                let existingEnd = moment(period.Date + "T" + period.EndTime);
                if (existingStart.isBefore(newBlockEnd) && existingEnd.isAfter(newBlockStart)) {
                    amountRented += period.Amount;
                }
            });
            return amountRented;
        },

        addSelectedBlocks() {

            this.checkoverlap();
            let blocks = [...this.blocks.filter(x => x.Amount > 0)];

            blocks.map(x => {

                let existing = this.state.cart.blocks.filter(block => block.Id == x.Id &&
                    block.Date == this.selectedDate);

                if (existing.length > 0) {
                    // remove the existing
                    this.state.cart.blocks.splice(this.state.cart.blocks.indexOf(existing[0]), 1);
                }

                x.ArticleId = this.detailArticle.Id;
                x.Title = this.store.getArticleTitle(this.detailArticle);
                x.Date = this.selectedDate;
                x.DeliveryAvailable = this.detailArticle.DeliveryAvailable;
                x.FriendlyDate = moment(this.selectedDate).format("DD-MM-YYYY");
                this.state.cart.blocks.push(x);

            });
            this.detailArticle = null;
            this.showAvailabilty = false;
            this.store.setPeriod(moment(this.selectedDate), moment(this.selectedDate));
            this.store.updateCart();

        },
        enlargeImage(article) {
            this.detailArticle = article;
            this.showEnlargeModal = true;
        },
        enlargeAvailabilty(article) {
            this.detailArticle = article;
            this.showAvailabilty = true;
            this.fetchBlockAvailability();
        },
        fetchBlockAvailability() {

            if (this.selectedDate == null) { return; }

            this.isLoading = true;
            this.noblocksAvailableMessage();

            this.blocks = [];
            if (this.detailArticle !== null) {
                axios.get('/api/article/blockavailability/' + this.detailArticle.Id, {
                    params: {
                        startDate: moment(this.selectedDate).format("YYYY-MM-DD"),
                        locationId: this.store.state.selectedLocation.Id
                    }
                }).then(response => {
                    const result = response.data;
                    console.log(result);
                    result.forEach(x => {
                        // check if already added to the cart
                        let existing = this.state.cart.blocks.find(block => block.Id === x.Id &&
                            block.Date === this.selectedDate);

                        if (existing) {
                            this.blocks.push(existing);
                        } else {
                            x.Date = this.selectedDate;
                            Vue.set(x, 'OriginalAmountAvailable', x.AmountAvailable > 0 ? x.AmountAvailable : 0);
                            Vue.set(x, 'AmountRented', 0);
                            this.blocks.push(x);
                        }
                    });
                    this.isLoading = false;
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            }
        },
        amountChanged(article) {
            this.store.calculateArticleTotal(article);
        }
    }
};
</script>
