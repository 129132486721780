<template>

    <div>
        <div class="full-width content">
            <h5>{{ store.locale.yourDetails }}</h5>
            <hr />
            <p>{{ store.locale.yourDetailsDescription }}</p>
        </div>
        <!--- //Content -->
        <div class="full-width">

            <div class="row">
                <div class="col-lg-8">

                    <div class="row">

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="honorific-prefix">{{ store.locale.salutation }}</label>
                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'nl'">
                                    <option value="Dhr.">De heer</option>
                                    <option value="Mevr.">Mevrouw</option>
                                    <option value="Mx.">Anders</option>
                                </select>

                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'en'">
                                    <option value="Dhr.">Sir</option>
                                    <option value="Mevr.">Madam</option>
                                    <option value="Mx.">Other</option>
                                </select>

                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'es'">
                                    <option value="Dhr.">Señor</option>
                                    <option value="Mevr.">Señora</option>
                                    <option value="Mx.">Otro</option>
                                </select>

                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'it'">
                                    <option value="Dhr.">Signore</option>
                                    <option value="Mevr.">Signora</option>
                                    <option value="Mx.">Altro</option>
                                </select>

                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'fr'">
                                    <option value="Dhr.">Monsieur</option>
                                    <option value="Mevr.">Madame</option>
                                    <option value="Mx.">Autre</option>
                                </select>

                                <select class="form-select" name="honorific-prefix" autocomplete="honorific-prefix"
                                    v-model="person.salutation" v-if="store.locale.locale == 'de'">
                                    <option value="Dhr.">Herr</option>
                                    <option value="Mevr.">Frau</option>
                                    <option value="Mx.">Andere</option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="given-name">{{ store.locale.firstName }}</label>
                                <input type="text" class="form-control" name="given-name" autocomplete="given-name"
                                    v-model="person.firstName" />
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="family-name">{{ store.locale.name }}</label>
                                <input type="text" id="name" name="family-name" autocomplete="family-name"
                                    v-model="person.name" />
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="!store.state.tenant.UseStudentForm">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="placeofstay">{{ store.locale.placeOfStay }}</label>
                                <mf-autocomplete :items="getAvailableAccommodations()" name="placeofstay"
                                    autocomplete="off" v-model="person.placeOfStay"></mf-autocomplete>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="specifiedLocation">{{ store.locale.placeOfStaySpecified }}</label>
                                <input type="text" id="specifiedLocation" name="specifiedLocation" autocomplete="off"
                                    v-model="person.locationSpecified" />
                            </div>
                        </div>
                    </div>


                    <div v-if="store.state.tenant.DeliveryEnabled">
                        <div class="row" v-if="deliveryBoxVisible">
                            <div class="col-lg-12">
                                <div class="alert alert-info" v-if="!store.state.hasDeliveryAvailableOnDay">
                                    {{ store.locale.deliveryUnavailable }}
                                </div>
                                <div v-else class="alert"
                                    :class="{ 'alert-warning': !pickupEnabled, 'alert-success': pickupEnabled }">
                                    <h3>{{ store.locale.deliveryTitle }}?</h3>
                                    <p>
                                        {{ store.state.tenant.DeliveryRemark }}
                                    </p>

                                    <div class="form-group" v-if="!store.state.tenant.DeliveryFixed">
                                        <div class="checkbox">
                                            <label>
                                                <input type="checkbox" v-model="isPickupOrder"
                                                    :disabled="!pickupEnabled" style="background-color: #efefef;" /> {{
                                                        store.locale.deliveryLabel }}
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="isPickupOrder && store.getUnDeliverableItemsInCart().length > 0"
                                    class="alert alert-danger">
                                    {{ store.locale.deliveryNotPossible }}

                                    <ul>
                                        <li v-for="article in store.getUnDeliverableItemsInCart()"
                                            :key="'undeliverable_' + article.Id">
                                            {{ article.Title }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-if="!store.state.tenant.UseStudentForm">
                        <h5>{{ store.locale.customerAddress }}</h5>
                        <hr />
                    </div>

                    <div class="row">
                        <div class="col-lg-8">
                            <div class="form-group">
                                <label for="address">{{ store.locale.street }}</label>
                                <input type="text" id="address" name="address-line1" autocomplete="address-line1"
                                    v-model="person.address" />
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="form-group">
                                <label for="streetNumber">{{ store.locale.streetNumber }}</label>
                                <input type="text" id="streetNumber" name="address-line2" autocomplete="address-line2"
                                    v-model="person.streetNumber" />
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="form-group">
                                <label for="streetSuffix">{{ store.locale.streetSuffix }}</label>
                                <input type="text" id="streetSuffix" name="address-line3" autocomplete="address-line3"
                                    v-model="person.streetSuffix" />
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="postalZipCode">{{ store.locale.postalZipCode }}</label>
                                <input type="text" id="postalZipCode" name="postal-code" autocomplete="postal-code"
                                    v-model="person.postalZipCode" />
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="form-group">
                                <label for="city">{{ store.locale.city }}</label>
                                <input type="text" id="city" name="address-level1" autocomplete="address-level1"
                                    v-model="person.city" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="country">{{ store.locale.country }}</label>
                                <select 
                                        class="form-select" 
                                        id="additional-country" 
                                        autocomplete="country-name"
                                        v-model="person.country">
                                        <option v-for="country in filteredCountries" :key="country.code" :value="country.code">
                                            {{ country.name[store.locale.locale] }}
                                        </option>
                                    </select>

                            </div>
                        </div>
                    </div>

                    <br />
                    <h5>{{ store.locale.contactDetails }}</h5>
                    <hr />

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="email">{{ store.locale.emailAddress }}</label>
                                <input type="email" id="email" name="email" autocomplete="email"
                                    v-model="person.emailaddress" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="email2">{{ store.locale.emailAddressConfirm }}</label>
                                <input type="email" id="email2" autocomplete="off"
                                    v-model="person.emailaddressConfirm" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="tel">{{ store.locale.phoneNumber }}</label>
                                <input type="text" id="number" name="tel" autocomplete="phonenumber"
                                    v-model="person.phonenumber" />
                            </div>
                        </div>
                    </div>

                    <div v-if="store.state.tenant.UseStudentForm">
                        <br />
                        <h5>{{ store.locale.additionalAddress.label }}</h5>
                        <hr />

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="school">{{ store.locale.additionalAddress.firstName }}</label>
                                    <select class="form-select" name="school" autocomplete="off"
                                        v-model="person.additionalAddress.firstName">
                                        <option value="Howest">Howest</option>
                                        <option value="VIVES">VIVES</option>
                                        <option value="Katholieke Universiteit Leuven KULAK">Katholieke Universiteit
                                            Leuven KULAK</option>
                                        <option value="UGent">UGent</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="additional-name">{{ store.locale.additionalAddress.name }}</label>
                                    <input type="text" id="additional-name" name="family-name"
                                        autocomplete="family-name" v-model="person.additionalAddress.name" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <label for="additional-street">{{ store.locale.additionalAddress.street }}</label>
                                    <input type="text" id="additional-street" autocomplete="off"
                                        v-model="person.additionalAddress.street" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="additional-streetnumber">{{ store.locale.additionalAddress.streetNumber
                                        }}</label>
                                    <input type="text" id="additional-streetnumber" autocomplete="off"
                                        v-model="person.additionalAddress.streetNumber" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="additional-streetsuffix">{{ store.locale.additionalAddress.streetSuffix
                                        }}</label>
                                    <input type="text" id="additional-streetsuffix" autocomplete="off"
                                        v-model="person.additionalAddress.streetSuffix" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="additional-postalZipCode">{{
                                        store.locale.additionalAddress.postalZipCode }}</label>
                                    <input type="text" id="additional-postalZipCode" autocomplete="off"
                                        v-model="person.additionalAddress.postalZipCode" />
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label for="additional-city">{{ store.locale.additionalAddress.city }}</label>
                                    <input type="text" id="additional-city" autocomplete="off"
                                        v-model="person.additionalAddress.city" />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="additional-country">{{ store.locale.additionalAddress.country }}</label>
                                    <select 
                                        class="form-select" 
                                        id="additional-country" 
                                        autocomplete="country-name"
                                        v-model="person.additionalAddress.country">
                                        <option v-for="country in filteredCountries" :key="country.code" :value="country.code">
                                            {{ country.name[store.locale.locale] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <br />
                        <h5>{{ store.locale.companyDetails }}</h5>
                        <hr />

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="organization">{{ store.locale.companyName }}</label>
                                    <input type="text" id="organization" name="organization" autocomplete="organization"
                                        v-model="person.companyName" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="organization-vat">{{ store.locale.companyVatNumber }}</label>
                                    <input type="text" id="organization-vat" name="vat-number" autocomplete="vat-number"
                                        v-model="person.companyVatNumber" />
                                </div>
                            </div>
                        </div>

                        <hr />
                    </div>

                    <div class="row" v-if="store.state.tenant.AllowRemark">
                        <div class="col-lg-12">
                            <label for="remark">{{ store.locale.remark }}</label>
                            <textarea id="remark" autocomplete="off" class="form-control"
                                v-model="person.remark"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="termsAccepted" id="termsCheckbox" />
                                    <label class="form-check-label" for="termsCheckbox">
                                        {{ store.locale.acceptTerms }}&nbsp;<a href="#" class="link text-decoration-underline" v-on:click.prevent="showTerms()">{{ store.locale.terms }}</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            {{ store.locale.reservationRemark }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            {{ store.locale.userDetailDepositInfo }}
                        </div>
                    </div>

                    <hr />

                    <div v-if="!formValid">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn medium" v-on:click="$emit('onback')"><i class="bi bi-arrow-left"></i>
                                    <span class="d-none d-sm-inline mx-2">{{ store.locale.backToCart }}</span></button>
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn medium color" v-on:click="validateForm"><span
                                        class="d-none d-sm-inline mx-2">{{ store.locale.advanceToPayment }}</span>
                                        <i class="bi bi-arrow-right"></i></button>
                            </div>
                        </div>


                        <div class="row d-block d-sm-none m-2">
                            <div class="col-12 text-right"><i>{{ store.locale.advanceToPayment }}</i></div>
                        </div>
                    </div>

                    <div class="row" v-else>
                        <div class="col-lg-12">
                            <mf-spinner />
                        </div>
                    </div>

                    <p>&nbsp;</p>
                </div>
                <div class="col-lg-4 sidebar ">

                    <div class="widget">
                        <div class="summary">
                            <h5>{{ store.locale.bookingOverview }}</h5>

                            <span v-if="store.state.departureDate">
                                {{ store.locale.from }}: {{ store.state.departureDate.format("DD-MM-YYYY HH:mm")
                                }}<br />
                                {{ store.locale.untill }}: {{ store.state.returnDate.format("DD-MM-YYYY HH:mm") }}<br />
                                {{ store.locale.numberOfDays }}: {{
                                    store.state.returnDate.diff(store.state.departureDate, 'days') + 1 }}<br />

                            </span>
                            <br />
                            <p v-if="!isPickupOrder">
                                {{ store.locale.pickupLocation }}: <strong>{{ store.state.selectedLocation.Name
                                    }}</strong>
                            </p>

                            <div v-for="(article, index) in cart.articles" :key="'article-cart-' + index">
                                <div class="pull-right">{{ store.getArticlePrice(article).toCurrencyString() }}</div>
                                {{ article.Amount }} x {{ store.getArticleTitle(article) }}
                            </div>

                            <div v-for="(article, index) in cart.relatedArticles" :key="'related-article-' + index">
                                <div class="pull-right">{{ store.getArticlePrice(article).toCurrencyString() }}</div>
                                {{ article.Amount }} x {{ store.getArticleTitle(article) }}
                            </div>


                            <hr v-if="cart.blocks.length > 0" />

                            <div v-for="(article, index) in cart.blocks.filter(x => x.Amount > 0)"
                                :key="'article-block-' + index" style="margin-top:15px">
                                <div class="pull-right">{{ (article.Price * article.Amount).toCurrencyString() }}</div>
                                {{ article.Amount }} x {{ store.getArticleTitle(article) }}<br />
                                {{ article.FriendlyDate }} - {{ article.StartTime }} {{ store.locale.periodAbbreviation
                                }} {{ article.EndTime }}
                            </div>

                            <hr />

                            <div v-if="isPickupOrder">
                                <i class="bi bi-truck"></i> {{ store.locale.deliveryTitle }} <i class="bi bi-check"></i>
                                <hr />
                            </div>

                            <div class="row" v-if="cart.pricing.subTotal != total">
                                <div class="col-lg-6">{{ store.locale.subTotal }}</div>
                                <div class="col-lg-6 text-right">{{ cart.pricing.subTotal.toCurrencyString() }}</div>
                            </div>

                            <div class="row" v-if="cart.pricing.reservationCosts > 0">
                                <div class="col-lg-6">{{ store.locale.reservationCosts }}</div>
                                <div class="col-lg-6 text-right">{{ cart.pricing.reservationCosts.toCurrencyString() }}
                                </div>
                            </div>

                            <div class="row" v-if="deliveryCosts > 0">
                                <div class="col-lg-6">{{ store.locale.deliveryTitle }}</div>
                                <div class="col-lg-6 text-right">{{ deliveryCosts.toCurrencyString() }}</div>
                            </div>
                            <div class="row" v-if="calculatedDiscount > 0">
                                <div class="col-lg-6">{{ store.locale.discount }}</div>
                                <div class="col-lg-6 text-right">- {{ calculatedDiscount.toCurrencyString() }} </div>
                            </div>

                            <div class="row" v-if="cart.pricing.deposit > 0">
                                <div class="col-lg-6">{{ store.locale.deposit }}</div>
                                <div class="col-lg-6 text-right">{{ cart.pricing.deposit.toCurrencyString() }}</div>
                            </div>

                            <div class="row" v-if="total > 0">
                                <div class="col-lg-4">
                                    <h5>{{ store.locale.total }}</h5>
                                </div>
                                <div class="col-lg-8 text-right">
                                    <h5>{{ (total - calculatedDiscount + deliveryCosts).toCurrencyString() }}</h5>
                                </div>
                            </div>

                            <div v-if="percentageToPay != 100 && percentageToPay > 0">
                                <span>
                                    {{ store.locale.amountToPay }}:<br />
                                    <small>
                                        {{ percentageToPay }}% {{ store.locale.subTotal }} + {{
                                            store.locale.reservationCosts }}
                                    </small>
                                </span>
                                <span class="pull-right">{{ (((total - calculatedDiscount + deliveryCosts -
                                    cart.pricing.reservationCosts) / 100 * percentageToPay) +
                                    cart.pricing.reservationCosts).toCurrencyString() }}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>

</template>

<script>
import moment from 'moment';
import Spinner from './Spinner.vue';
import Autocompleter from './Autocompleter.vue';

import '@/jquery';
import "bootstrap";
import bootbox from 'bootbox';

import countries from '@/i18n/countries';

export default {
    props: ['store', 'cart', 'total', 'discount', 'percentageToPay', 'placeofstay'],
    components: {
        'mf-spinner': Spinner,
        'mf-autocomplete': Autocompleter
    },
    data: function () {
        return {
            formValid: false,
            termsAccepted: false,
            person: {
                name: "",
                phonenumber: "",
                emailaddress: "",
                emailaddressConfirm: "",
                remark: "",
                placeOfStay: this.placeofstay,
                pickupLocation: "",
                country: this.store.locale.defaultCountryCode,
                additionalAddress: {
                    firstName: "",
                    name: "",
                    street: "",
                    streetSuffix: "",
                    streetNumber: "",
                    postalZipCode: "",
                    city: "",
                    country: ""
                }
            },
            isPickupOrder: this.store.state.tenant.DeliveryFixed,
            pickupEnabled: false,
            hasPossibleDeliveries: this.store.state.accommodations.filter(x => x.isAvailable).length > 0 || !this.store.state.tenant.ExplicitDeliveryAccommodations,
            deliveryCosts: this.store.state.tenant.DeliveryFixed ? this.store.state.tenant.DeliveryCosts == '' ? 0 : Number(this.store.state.tenant.DeliveryCosts) : 0,
            countries: countries
        }
    },
    computed: {

        filteredCountries() {
            return this.countries.filter(country => country.name[this.store.locale.locale]);
        },

        calculatedDiscount() {

            let discountsDisabledWithPickupOrder = this.isPickupOrder && this.store.state.tenant.DiscountDisabledWithDelivery;

            if (discountsDisabledWithPickupOrder || !this.store.state.hasDiscountAvailableOnDay) {
                return 0;
            }
            return this.discount;
        },
        deliveryBoxVisible() {

            if (!this.hasPossibleDeliveries) {
                return false;
            }

            if (this.store.state.departureDate === null) {
                return false;
            }
            const earliestDeliveryDate = moment().startOf('day').add(this.store.state.departureDate.hours(), 'hours').add(this.store.state.tenant.DeliveryMinDaysAhead, 'days');
            const daysRemainingForDelivery = this.store.state.departureDate.clone().startOf('day').diff(earliestDeliveryDate, 'days');

            if (daysRemainingForDelivery < 0) {
                return false;
            }

            if (this.pickupEnabled)
                return true;

            return this.store.state.tenant.PermanentDeliveryOption;
        }
    },
    watch: {
        isPickupOrder: function () {
            this.person.pickupLocation = "";
            this.deliveryCosts = 0;

            if (!this.pickupEnabled && !this.store.state.tenant.DeliveryFixed) {
                return false;
            }

            if (this.store.state.tenant.DeliveryFixed) {
                this.isPickupOrder = true;
            }

            if (this.isPickupOrder === true) {
                this.deliveryCosts = this.store.state.tenant.DeliveryCosts == '' ? 0 : Number(this.store.state.tenant.DeliveryCosts);
                this.person.pickupLocation = "DELIVERY"
            }

            return this.isPickupOrder;
        },
        'person.placeOfStay'(val) {

            if (this.store.state.tenant.ExplicitDeliveryAccommodations) {
                var selected = this.store.state.accommodations.filter(x => x.isAvailable && x.value.toLowerCase() == val.toLowerCase()).map(x => x.value);
                this.pickupEnabled = false;
                if (selected && selected.length == 1) {
                    this.pickupEnabled = true;
                }
            } else {
                this.pickupEnabled = true;
            }
        },
    },
    methods: {

        showTerms() {
            // eslint-disable-next-line
            this.store.showTerms = true;
        },

        getAvailableAccommodations() {
            return this.store.state.accommodations.map(x => x.value);
        },

        isValidEmail(value) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            return re.test(value);
        },
        validateForm() {

            if (this.isPickupOrder && this.store.getUnDeliverableItemsInCart().length > 0) {
                bootbox.alert(this.store.locale.formValidation.underliverableItemsInCart);
                return;
            }

            if (this.person.name.length < 3) {
                bootbox.alert(this.store.locale.formValidation.invalidName);
                document.getElementById("name").focus();
                return;
            }

            if (this.isPickupOrder) {
                if (this.person.placeOfStay.length < 3) {
                    bootbox.alert(this.store.locale.formValidation.invalidPlaceOfStay);
                    return;
                }
            }

            if (this.store.state.tenant.UseStudentForm) {
                if (this.person.additionalAddress.firstName.length < 3) {
                    bootbox.alert("Selecteer een school uit de lijst.");
                    return;
                }
                if (this.person.additionalAddress.name.length < 3) {
                    bootbox.alert("Studentnummer is verplicht.");
                    return;
                }
            }

            if (this.person.phonenumber.length < 3) {
                bootbox.alert(this.store.locale.formValidation.invalidPhoneNumber);
                document.getElementById("number").focus();
                return;
            }

            if (this.person.emailaddress.length < 3 || !this.isValidEmail(this.person.emailaddress)) {
                bootbox.alert(this.store.locale.formValidation.invalidEmailAddress);
                document.getElementById("email").focus();
                return false;
            }

            if (this.person.emailaddress !== this.person.emailaddressConfirm) {
                bootbox.alert(this.store.locale.formValidation.invalidConfirmEmailAddress);
                document.getElementById("email2").focus();
                return false;
            }

            if (!this.termsAccepted) {
                bootbox.alert(this.store.locale.formValidation.termsNotAccepted);
                return false;
            }

            this.formValid = true;
            this.$emit('formvalidated', this.person);

        }
    }
};
</script>