import Vue from 'vue';
import App from './App.vue';
import router from './router'; // Import the router instance
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
//@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
import './css/style.scss';

// Define the storefront and apiUrl
const storefront = window.location.hostname.split('.')[0];
const apiUrl = `https://demo.fietsreserveren-preview.nl/`;

// Set up Axios default base URL
import axios from 'axios';
axios.defaults.baseURL = apiUrl;
//axios.defaults.headers.common['Accept-Language'] = 'nl-NL';

axios.defaults.headers.common['X-Storefront'] = storefront;


function loadThemeStylesheet(url) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild(link);
}

// Load the theme CSS
loadThemeStylesheet(`${apiUrl}/theme`);

// Add the currency formatting prototype method
Number.prototype.toCurrencyString = function () {
  if (isNaN(this.toFixed(2))) {
    return "€ 0";
  }
  return "€ " + this.toFixed(2).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ').replace(".", ",");
};

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
