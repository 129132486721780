<template>
    <div class="row" v-if="!store.state.tenant.HideReservationPeriodHeader">

        <div class="form-group col-lg-3" v-if="suggestedLocations.length > 1">
            <label for="location">{{ store.locale.locationName }}</label>
            <select v-model="selectedLocation" v-on:change="onLocationChanged">
                <option v-for="(location, index) in suggestedLocations" :key="'location-' + index"
                    :selected="selectedLocation.Id === location.Id" :value="location">{{ location.Name }}</option>
            </select>
        </div>

        <div class="form-group col-lg-3">
            <label for="aankomst_datum">{{ store.locale.pickupDate }}</label>
            <mf-datepicker :selected-date="departureDate" :disabled-dates="store.state.selectedLocation.BlockedDates"
                element-id="dep-date" :min-date="minDate" :datechanged="departureDateChanged" />
        </div>

        <div class="form-group col-lg-3" v-if="suggestionsforstart.length > 1">
            <label for="arrival">{{ store.state.tenant.Name === 'Soepboer' ? store.locale.pickupTimeCustom :
                store.locale.pickupTime }}</label>
            <select v-model="selectedTimeSuggestion">
                <option v-for="(time, index) in suggestionsforstart" :key="'time-' + index"
                    :selected="time.Time === selectedTimeSuggestion" :value="time.Time">{{ time.Time }}</option>
            </select>
        </div>

        <div class="form-group col-lg-3">
            <label for="return-date">{{ store.locale.returnDate }}</label>
            <mf-datepicker :selected-date="returnDate" :disabled-dates="store.state.selectedLocation.BlockedDates"
                element-id="return-date" :min-date="minDate" :datechanged="returnDateChange" />
        </div>
    </div>

</template>
<script>
import DatePicker from './DatePicker.vue';
import moment from 'moment';

export default {
    props: ['accommodations', 'locations', 'timesuggestions', 'store'],
    components: { 'mf-datepicker': DatePicker},
    data: function () {
        return {
            returnDate: this.store.state.returnDate == undefined ? moment().add(this.store.state.tenant.StartDateMinDaysAhead, 'days') : moment(this.store.state.returnDate),
            selectedAccommodation: "",
            accommodationMatches: [],
            suggestedLocations: this.locations,
            selectedLocation: this.locations[0],
            selectedTimeSuggestion: this.timesuggestions[0].Time,
            timeOpen: "",
            minDate: moment().add(this.store.state.tenant.StartDateMinDaysAhead, 'days'),
            departureDate: this.store.state.departureDate == undefined ? moment().add(this.store.state.tenant.StartDateMinDaysAhead, 'days') : moment(this.store.state.departureDate),
            suggestionsforstart: this.timesuggestions
        }

    },
    watch: {

        selectedTimeSuggestion: function () {
            this.onPeriodChanged();
        },
        selectedAccommodation: function () {
            var that = this;
            that.accommodationMatches = [];
            this.accommodations.forEach(suggestion => {
                const lowerCaseAccommodation = this.selectedAccommodation.toLowerCase();
                const lowerCaseSuggestion = suggestion.value.toLowerCase();
                if (lowerCaseSuggestion.startsWith(lowerCaseAccommodation) || lowerCaseSuggestion.includes(lowerCaseAccommodation)) {
                    this.accommodationMatches.push(suggestion);
                }
            });

            that.accommodationMatches = that.accommodationMatches.slice(0, 5);
        },
        selectedLocation() {
            this.returnDateChange(this.departureDate.toDate());
        }
    },
    created: function () {
        this.setDefaultLocation();
        this.onLocationChanged();
    },
    methods: {
        onLocationChanged() {
            this.$emit('locationchanged', this.selectedLocation);

            if (this.store.state.tenant.Name === 'Soepboer') {
                if (this.selectedLocation.Name == "Veerdam (bij de boot)") {
                    this.suggestionsforstart = this.timesuggestions.filter(x => x.Id > 0);
                } else {
                    this.suggestionsforstart = this.timesuggestions.filter(x => x.Id < 23);
                    if (this.selectedTimeSuggestion > 22) {
                        this.selectedTimeSuggestion = 22;
                    }
                }
            }

            window.setTimeout(() => this.onPeriodChanged(), 500);
        },

        setDefaultLocation() {
            const urlParams = new URLSearchParams(window.location.search);
            const requestedLocation = urlParams.get('location');
            if (requestedLocation) {
                let foundLocations = this.locations.filter(x => x.Name.toLowerCase() == requestedLocation.toLowerCase());
                if (foundLocations.length > 0) {
                    this.selectedLocation = foundLocations[0];
                    this.onLocationChanged();
                }
            }

        },

        onAccommodationInputChanged() {

            this.suggestedLocations = this.locations.filter((value) => { return value.Name != "Bezorgen" });

            var matchingAccommodation = this.accommodations.find(x => x.isAvailable && x.data.toLowerCase() == this.selectedAccommodation.toLowerCase());
            if (matchingAccommodation != null) {

                this.suggestedLocations.push({
                    Id: -1,
                    Name: "Bezorgen",
                    PickupAllowancePercentage: 0,
                    Address: "",
                    BlockedDates: this.locations[0].BlockedDates
                })
            }
        },

        departureDateChanged(newStartDate) {

            let currentEndDate = this.getTimeClosedAtDate(this.returnDate);
            if (moment(currentEndDate).isBefore(moment(newStartDate))) {
                this.returnDate = this.getTimeClosedAtDate(moment(newStartDate));
            }

            this.departureDate = moment(newStartDate);


            this.onPeriodChanged();
        },

        returnDateChange(newReturnDate) {

            let currentDepartureDate = this.departureDate;
            if (moment(currentDepartureDate).isAfter(newReturnDate)) {
                this.returnDate = this.getTimeClosedAtDate(moment(currentDepartureDate));
            } else {
                this.returnDate = moment(newReturnDate);
            }
            this.onPeriodChanged();
        },

        onPeriodChanged() {
            if (this.timesuggestions.length > 0) {
                let timeBlock = this.selectedTimeSuggestion.replace(/\D/g, '');
                this.departureDate.set({ hour: timeBlock.substring(0, 2), minute: timeBlock.substring(2, 4) });
            } else {
                this.departureDate = this.getTimeOpenAtDate(this.departureDate);
            }

            if (this.departureDate.isBefore(this.minDate)) {
                this.departureDate = this.minDate;
            }

            let data = {
                departureDate: this.departureDate,
                returnDate: this.getTimeClosedAtDate(this.returnDate)
            };

            this.$emit("periodchanged", data);

        },

        getWorkingHoursForPeriod(date) {
            if (date == null || !this.store.state.workinghours) return [];
            return this.store.state.workinghours.filter(x => moment(x.DateFrom, "YYYY-MM-DD").isSameOrBefore(date.format("YYYY-MM-DD")))
        },

        GetWorkinghoursForDate(date) {
            let workingHoursSet = this.getWorkingHoursForPeriod(date);
            if (workingHoursSet.length > 0) {

                return workingHoursSet[workingHoursSet.length - 1].Schedule.filter(x => x.DayOfWeek === date.day()).sort(function (a, b) {
                    return parseInt(b.StartTime.replace(":", "")) - parseInt(a.StartTime.replace(":", ""))
                }).map(x => x.StartTime);
            }
            return [];
        },

        getTimeOpenAtDate(date) {
            if (date == null) {
                this.closedOnStart = true;
                return null;
            }

            //this.closedOnStart = false;
            let workingHoursSet = this.getWorkingHoursForPeriod(date);

            if (workingHoursSet.length > 0) {

                const results = workingHoursSet[workingHoursSet.length - 1].Schedule.filter(x => x.DayOfWeek === date.day()).sort(function (a, b) {
                    return parseInt(a.StartTime.replace(":", "")) - parseInt(b.StartTime.replace(":", ""))
                });

                if (results.length > 0) {
                    let firstOpeningTimeOfDay = results[0];
                    if (firstOpeningTimeOfDay != undefined) {
                        let dt = date.format("YYYY-MM-DD") + ' ' + firstOpeningTimeOfDay.StartTime;
                        let result = moment(dt, "YYYY-MM-DD HH:mm");
                        return result;
                    }
                }
            }

            this.closedOnStart = true;
            let dt = date.format("YYYY-MM-DD") + ' 09:00';
            let result = moment(dt, "YYYY-MM-DD HH:mm");
            return result;

        },

        getTimeClosedAtDate(date) {

            if (date == null) {
                this.closedOnStart = true;
                return null;
            }
            if (this.selectedLocationName == "Veerdam (bij de boot)") {
                let dateTimeOpen = date.clone();
                dateTimeOpen.set({ hour: 19, minute: 30, second: 0, millisecond: 0 });
                return dateTimeOpen;
            }

            let workingHoursSet = this.getWorkingHoursForPeriod(date);

            if (workingHoursSet.length > 0) {
                const results = workingHoursSet[workingHoursSet.length - 1].Schedule.filter(x => x.DayOfWeek === date.day()).sort(function (a, b) {
                    return parseInt(a.StartTime.replace(":", "")) - parseInt(b.StartTime.replace(":", ""))
                });

                if (results.length > 0) {
                    let lastOpeningTimeOfDay = results[results.length - 1];
                    if (lastOpeningTimeOfDay === undefined) {
                        return date.roundNext15Min();
                    } else {
                        let startTime = lastOpeningTimeOfDay.StartTime.split(':');
                        let dateTimeOpen = date.clone();
                        dateTimeOpen.set({ hour: startTime[0], minute: startTime[1], second: 0, millisecond: 0 })
                        let dateTimeClosed = dateTimeOpen.add(lastOpeningTimeOfDay.Duration, 'minutes');
                        return dateTimeClosed;
                    }
                }
            }

            let dt = date.format("YYYY-MM-DD") + ' 18:00';
            let result = moment(dt, "YYYY-MM-DD HH:mm");
            return result;

        },
    }

}

</script>