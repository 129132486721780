<template>
    <transition name="mf-modal">
        <div class="mf-modal-mask">
            <div class="mf-modal-wrapper">
                <div class="mf-modal-container">

                    <div class="mf-modal-header">
                        <button type="button" class="btn-close pull-right" v-on:click="$emit('close')"></button>
                        <slot name="header"></slot>
                    </div>

                    <div class="mf-modal-body">
                        <slot name="body"></slot>
                    </div>

                    <div class="mf-modal-footer">
                        <slot name="footer">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'mf-modal',
        props: ['ok-clicked'],
    };
</script>

<style>
    .mf-modal-mask {
        position: fixed;
        z-index: 1020;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
        text-align: left;
    }



    .mf-modal-container {
        padding: 20px 45px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        height: 100vh;
        overflow-y: auto;
    }


    .mf-modal-body {
        margin: 20px 0;
    }

    .mf-modal-default-button {
        float: right;
    }

    @media only screen and (min-width: 768px) {

        .mf-modal-container {
            padding: 20px;
            width: 900px;
            margin: 0px auto;
        }

        .mf-modal-wrapper {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .mf-modal-enter {
        opacity: 0;
    }

    .mf-modal-leave-active {
        opacity: 0;
    }

    .mf-modal-enter .mf-modal-container,
    .mf-modal-leave-active .mf-modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>