@using Resources;

<template>
    <div>


        <div class="advanced-search grey" v-if="CurrentStep == 1">

            <div class="wrap">
                <form role="form" method="post" action="#" v-if="isLocationLoaded">
                    <mf-searchbar :store="store" :locations="state.locations" :timesuggestions="state.timesuggestions"
                        v-on:periodchanged="onPeriodChanged" v-on:locationchanged="store.setLocation($event)">
                    </mf-searchbar>
                </form>
            </div>
        </div>


        <div class="wrap">

            <div class="row" v-if="onDepartureMessage">
                <div class="col-lg-12">
                    <div class="alert alert-info">
                        {{ onDepartureMessage }}
                    </div>
                </div>
            </div>

            <div class="row" v-if="onReturnMessage">
                <div class="col-lg-12">
                    <div class="alert alert-danger">
                        {{ onReturnMessage }}
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-12" v-if="CurrentStep == 1">

                    <div class="row">

                        <div class="col-lg-3">
                            <div class="sidebar">

                                <div class="widget">
                                    <ul class="categories">
                                        <li v-for="(category, index) in state.categories" :key="'article-category_' + index" v-on:click="changeCategory(category)"
                                            :class="state.selectedCategory.Id === category.Id ? 'active' : ''">
                                            {{ store.getArticleTitle(category) }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">

                            <p v-html="formattedDescription" v-if="formattedDescription.length > 0"
                                style="margin-bottom: 20px"></p>

                            <mf-article-list :articles="state.selectedCategory.Articles" :store="store"
                                :cartviewclicked="cartviewclicked"></mf-article-list>
                                
                            <button class="btn medium color pull-right" v-if="numberOfArticles > 0"
                                v-on:click="cartviewclicked">
                                {{ store.locale.advanceToBookingOverview }} <i class="bi bi-arrow-right"></i>
                            </button>
                            <mf-spinner v-if="isLoadingArticles" />


                        </div>
                    </div>
                </div>

                <p>&nbsp;</p>
            </div>
        </div>

        <div id="infoModal" class="modal fade" role="dialog">
            <div class="modal-dialog text-center">

                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ store.getArticleTitle(infoArticle) }}</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="featured-image" v-if="infoArticle.Image">
                            <img
                                :src="'https://api.mijnfietsverhuur.nl/file/get?reference=' + infoArticle.Image" />
                        </div>

                        <table class="table" v-if="infoArticle.FixedPrice > 0">
                            <tr>
                                <th>@Resources.lblPrice</th>
                            </tr>
                            <tr>
                                <td>{{ infoArticle.FixedPrice.toCurrencyString() }}</td>
                            </tr>
                        </table>

                        <table class="table table-responsive">
                            <tr>
                                <th v-for="(price, index) in pricing" :key="'price_'+index" style="text-align: center">
                                    <span v-if="price.FromHours < 5">
                                        ({{ price.FromHours }} @Resources.lblHour)
                                    </span>
                                    <span v-if="price.FromHours == 5">
                                        1 @Resources.lblDay
                                    </span>
                                    <span v-if="price.FromHours > 5">
                                        @Resources.lblDay {{ (price.FromHours / 24) + 1 }}
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <td v-for="(price, index) in pricing" :key="'price-day-' + index" class="text-center">
                                    <span v-if="price.Price == undefined || price.Price < 0">-</span>
                                    <span v-if="price.Price != undefined && price.Price > 0">{{
                                        price.Price.toCurrencyString() }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import moment from 'moment';
import ArticleTable from './ArticleTable.vue';
import Spinner from './Spinner.vue';
import SearchBar from './SearchBar.vue';

const getParameterByName = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export default {
    props: ["store", "shoppingcartchanged", "cartviewclicked", "isLocationLoaded"],
    components: {
        'mf-article-list': ArticleTable,
        'mf-searchbar': SearchBar,
        'mf-spinner': Spinner
    },
    data: function () {
        return {
            isLoadingArticles: false,
            closedOnStart: false,
            closedOnReturn: false,
            person: {
                name: "",
                emailaddress: "",
                emailaddressConfirm: "",
                phonenumber: ""
            },
            infoArticle: {},
            selectedLocationName: getParameterByName("location"),
            initialCategory: getParameterByName("categorie"),
            locationAllowance: 0,
            pricing: {
                deposit: 0,
                subTotal: 0,
                extraDiscount: 0,
                reservationCosts: 0
            },
            onDepartureMessage: "",
            onReturnMessage: "",
            selectedAccommodation: "",
            accommodationMatches: [],
            selectedTimeSuggestion: {},
            CurrentStep: 1,
            showShoppingCart: false,
            state: this.store.state,
        }
    },

    created: function () {

        this.pricing.reservationCosts = Number(this.state.tenant.WebsiteReservationCosts);

        this.store.setLocation(this.state.locations[0]);

        if (this.initialCategory) {
            let foundCategories = this.state.categories.filter(x => x.Title.toSlug() == this.initialCategory.toSlug())
            if (foundCategories.length > 0) {
                this.changeCategory(foundCategories[0]);
            } else {
                this.changeCategory(this.state.categories[0]);
            }

        } else {
            this.changeCategory(this.state.categories[0]);
        }

        this.setAllowance();
    },
    watch: {
        infoArticle: function () {
            if (this.infoArticle.Prices == null) {
                axios.get("/api/article/" + this.infoArticle.Id + "/pricing")
                    .then(response => {
                        this.pricing = response.data;
                    })
                    .catch(error => {
                        console.error("There was an error fetching the pricing data:", error);
                    });
            }
        },
        selectedLocationName: function () {
            this.setAllowance();
        },

    },

    computed: {

        numberOfArticles: function () {
            let articles = this.store.state.cart.articles;
            let articleCount = (articles || []).reduce(function (r, a) {
                return r + (a.Amount || 0);
            }, 0);

            let blockCount = (this.store.state.cart.blocks || []).reduce(function (r, a) {
                return r + (a.Amount || 0);
            }, 0);
            return articleCount + blockCount;
        },

        formattedDescription: function () {
            return this.formatMarked(this.store.getArticleDescription(this.state.selectedCategory))
        },
        departureDateFormatted: function () {
            if (this.state.departureDate === null)
                return "";
            return this.state.departureDate.format("D-M-Y h:mm");
        },
        returnDateFormatted: function () {
            if (this.state.returnDate === null)
                return "";
            return this.state.returnDate.format("D-M-Y h:mm");
        }
    },

    methods: {

        onPeriodChanged(value) {

            this.onDepartureMessage = "";
            this.onReturnMessage = "";

            if (value.departureDate && value.departureDate.isValid()) {

                let departureDayRemark = this.store.getStartDayMessage(value.departureDate);
                let returnDayRemark = this.store.getReturnDayMessage(value.returnDate);

                this.onDepartureMessage = departureDayRemark;
                this.onReturnMessage = returnDayRemark;

                if (departureDayRemark === returnDayRemark) {
                    this.onReturnMessage = "";
                }

                this.store.setPeriod(value.departureDate, value.returnDate);
            }




        },

        getArticles(categoryId) {
            this.isLoadingArticles = true;
            axios.get("/api/article/categories/" + categoryId)
                .then(response => {
                    const data = response.data;

                    data.map(option => {
                        const extendedProps = {
                            LinePriceTotal: 0,
                            LinePriceTotalFormatted: ""
                        }

                        if (option.Related) {
                            option.Related.map(relatedArticle => {
                                return Object.assign(relatedArticle, extendedProps);
                            });
                        }

                        return Object.assign(option, extendedProps);
                    });

                    this.state.selectedCategory.Articles = data;
                    this.isLoadingArticles = false;
                })
                .catch(error => {
                    console.error("There was an error fetching the articles:", error);
                    this.isLoadingArticles = false;
                });
        },

        isLocationClosedAtDateByDeviation(date) {

            this.state.selectedLocation.BlockedDates.map(closed => {
                let closedDate = moment(closed);
                if (closedDate.startOf('day').isSame(date.clone().startOf('day'))) {
                    alert("Op deze locatie kan geen reserveringen meer aannemen voor deze datum.");
                }
            });
            return;
        },

        formatMarked(val) {
            //return val;
            if (val == undefined || val == null) {
                return "";
            }
            return DOMPurify.sanitize(marked(val));
        },

        advanceToDetails() {
            //if (this.getSelectedArticles().length == 0) {
            //    bootbox.alert("U heeft geen artikelen gekozen om te huren.")
            //    return;
            //}
            //CurrentStep = 2;
        },

        getLocation() {
            return this.state.locations.find(x => x.Name == this.selectedLocationName);
        },

        setAllowance() {
            if (this.state.selectedLocation != undefined) {
                if (this.locationAllowance != this.state.selectedLocation.PickupAllowancePercentage) {
                    this.locationAllowance = this.state.selectedLocation.PickupAllowancePercentage;
                    this.updatePricesForSelectedAticles();
                }
            }
        },


        changeCategory(category) {
            this.store.setSelectedCategory(category);
            if (this.state.selectedCategory.Articles == null) {
                this.getArticles(this.state.selectedCategory.Id);
            }
        }

    }
};
</script>