<template>
  <div class="payment-redirect">
    <input type="hidden" id="requestId" :value="requestId" />
    <main class="main" role="main">
      <div class="content">

        <div v-if="status === 'verify'">
            <h3>{{ getTranslation('StandBy') }}</h3>
            <p>{{ getTranslation('PaymentStatusIsBeingVerified') }}</p>
            <mf-spinner v-if="isLoading" />
            <div class="alert alert-warning">{{ getTranslation('DontCloseThisWindow') }}</div>
        </div>

        <div v-if="status === 'failed'">
            <h3>{{ getTranslation('FailedToVerifyPayment') }}</h3>
            <p>{{ getTranslation('FailedToVerifyPaymentMessage') }}</p>
            <br />
            <a href="/" class="btn btn-primary">{{ getTranslation('Home') }}</a>
        </div>

        <div v-if="status === 'verified'">
            <h3>{{ getTranslation('PaymentVerified') }}</h3>
            <p>{{ getTranslation('YouAreBeingForwarded') }}</p>
            <mf-spinner v-if="isLoading" />

        </div>

      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import Spinner from '@/components/Spinner.vue';
import translations from '@/i18n/paymentRedirect.json';

const paymentStatus = {
    SUCCESS: "1",
    SUCCESS_WITH_REDIRECT: "1;",
    CANCELLED: "2",
    EXPIRED: "3",
    FAILED: "-1"
};

export default {
    data() {
        return {
            requestId: this.$route.params.id,
            maxTry: 5,
            retryDelay: 3,
            tryCount: 0,
            status: 'verify', // 'verify', 'failed', 'verified'
            isLoading: true,
            locale: 'nl',
            translations: translations
        };
    },
    components: {
        'mf-spinner': Spinner,
    },
    mounted() {
        this.verify();
    },
    methods: {
        verify() {
            this.tryCount++;
            if (this.tryCount > this.maxTry) {
                this.showFailed();
                return;
            }
            const path = `/CheckPaymentResult/${this.requestId}`;
            axios.get(path).then((response) => {
                const data = response.data.toString();
                if (data === paymentStatus.SUCCESS) {
                    this.setVerified("bedankt");
                    localStorage.removeItem("state");
                    return;
                }
                if (data.startsWith(paymentStatus.SUCCESS_WITH_REDIRECT)) {
                    const redirectPath = data.split(";")[1];
                    window.location.href = redirectPath;
                    localStorage.removeItem("state");
                    return;
                }
                if (data === paymentStatus.CANCELLED) {
                    this.setVerified("geannuleerd");
                    return;
                }
                if (data === paymentStatus.EXPIRED) {
                    this.setVerified("verlopen");
                    return;
                }
                if (data === paymentStatus.FAILED) {
                    this.showFailed();
                    return;
                }
                setTimeout(this.verify, this.retryDelay * 1000);
            });
        },
        showFailed() {
            this.status = 'failed';
        },
        setVerified(page) {
            this.status = 'verified';
            setTimeout(() => {
                window.location.href = `/${page}`;
            }, 2500);
        },
        getTranslation(key) {
            return this.translations[this.locale][key];
        }
    },
};
</script>

<style scoped>
.payment-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.main {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 40px;
}

</style>