<template>
    <span>
        <vue2-datepicker 
            v-model="value" type="date" format="DD-MM-YYYY" :disabled-date="disabledDate"
            :min-date="minSelectableDate" :max-date="maxDate" :first-day-of-week="1" :time-picker="false"
            :clearable="false"
            @change="onDateChange" />
    </span>
</template>
<style>
    .mx-input-wrapper input {
        padding: 22px !important;
    }
    .mx-datepicker-main {
        color: #303438 !important;
    }
    .cell.current {
        background-color: #dcdcdc !important;
        color: #303438 !important;
    }
    .cell.selected {
        background-color: #303438 !important;
        color: #ffffff !important;
    }
</style>

<script>
import moment from 'moment';
import Vue2Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    props: ['disabledDates', 'elementId', 'datechanged', 'selectedDate', 'placeholder', 'minDate', 'initialBlank'],
    components: {
        Vue2Datepicker
    },
    data() {
        return {
            currentSelectedDate: this.selectedDate ? moment(this.selectedDate).toDate() : new Date(),
            value: this.minDate ? moment(this.minDate).toDate() : new Date(),
            minSelectableDate: this.minDate ? moment(this.minDate).toDate() : new Date(),
            maxDate: this.minDate ? moment(this.minDate).clone().add(18, 'months').toDate() : moment().add(18, 'months').toDate(),
        };
    },
    watch: {
        selectedDate(val) {
            // Handle changes to disabledDates if necessary
            this.value = moment(val).toDate();
        }
    },
    mounted() {
        this.findAvailableDate();

        if (this.initialBlank) {
            this.currentSelectedDate = null;
            this.value = null;
        } else {
            this.currentSelectedDate = moment(this.selectedDate).toDate();
            this.value = moment(this.selectedDate).toDate();

            if (!this.isDateIsAvailable(this.currentSelectedDate)) {
                this.findAvailableDate();
            }

            this.datechanged(this.currentSelectedDate);
        }
    },
    methods: {
        onDateChange(newDate) {
            this.currentSelectedDate = new Date(newDate);
            this.value = new Date(newDate);
            if (!this.isDateIsAvailable(this.currentSelectedDate)) {
                this.findAvailableDate();
            }
            this.datechanged(this.currentSelectedDate);
        },
        disabledDate(date) {
            const formattedMinDate = moment(this.minDate);
            const formattedMaxDate = moment(this.maxDate);
            return !this.isDateIsAvailable(date) || moment(date).isBefore(formattedMinDate) || moment(date).isAfter(formattedMaxDate); 
        },
        isDateIsAvailable(date) {
            return !this.disabledDates.some(dt => moment(dt).isSame(date, 'day'));
        },
        findAvailableDate() {
            let maxDaysToTry = 10;

            if (!this.currentSelectedDate) {
            this.currentSelectedDate = moment(this.minDate);
            } else {
            this.currentSelectedDate = moment(this.currentSelectedDate);
            }

            if (this.isDateIsAvailable(this.currentSelectedDate.toDate())) {
            this.value = this.currentSelectedDate.toDate();
            return;
            }

            for (let i = 0; i < maxDaysToTry; i++) {
            this.currentSelectedDate = this.currentSelectedDate.add(1, 'days');

            if (this.isDateIsAvailable(this.currentSelectedDate.toDate())) {
                this.value = this.currentSelectedDate.toDate();
                return;
            }
            }
        }
    }
};
</script>