@using Resources;

<template>
    <div v-if="!isLoading">

        <div class="sub-header">
            <div class="wrap">
                <div class="language">
                <a :href="store.state.tenant.WebsiteURL">{{ store.locale.backToSite }} <i class="bi bi-chevron-left"></i> </a>
                
                <a href="#" v-on:click="store.setLocale('es')"><img src="@/assets/flags/es.png"></a>
                <a href="#" v-on:click="store.setLocale('fr')"><img src="@/assets/flags/fr.png"></a>
                <a href="#" v-on:click="store.setLocale('it')"><img src="@/assets/flags/it.png"></a>
                <a href="#" v-on:click="store.setLocale('de')"><img src="@/assets/flags/de.png"></a>
                <a href="#" v-on:click="store.setLocale('en')"><img src="@/assets/flags/gb.png"></a>
                <a href="#" v-on:click="store.setLocale('nl')"><img src="@/assets/flags/nl.png"></a>
                </div>
            </div>
        </div>

        <div v-if="store.state.notification">
            <div
                :style="{ color: store.state.notification.FontColor, backgroundColor: store.state.notification.BackgroundColor, padding: '1.2rem' }">
                <strong>{{ store.getNotificationTitle(store.state.notification) }}</strong>
                <p style="margin: 0">
                    {{ store.getNotificationMessage(store.state.notification) }}
                </p>
            </div>
        </div>
        <main class="main" role="main">
            <a href="#top" class="anchor"></a>
            <mf-header :store="store" :cartviewclicked="viewCartClicked" />

            <section class="site-title color no-margin">
                <div class="wrap">
                    <h1 style="color: #ffffff">
                        {{ store.locale.onlineReservations }}
                    </h1>
                </div>
            </section>

            <section id="main">
                <mf-reservation-shop v-if="!showShoppingCart" :store="store"
                    :shoppingcartchanged="onSelectedArticlesChanged" :cartviewclicked="viewCartClicked"
                    :is-location-loaded="isLocationLoaded"></mf-reservation-shop>

                <mf-shoppingcart v-else :store="store" :articles="store.state.cart.articles"
                    :pricing="store.state.cart.pricing" :percentage-to-pay="store.state.tenant.WebsitePercentageToPay"
                    :closecart="closeShoppingCart"></mf-shoppingcart>
            </section>
            
            <footer class="footer black" role="contentinfo" id="footer" v-if="store.state">
                <div class="wrap">
                    <div class="row">
                        <article class="one-half">
                            <h6> {{ store.locale.about }} {{ store.state.tenant.Name }}</h6>
                            <p v-html="store.state.tenant.About"></p>
                        </article>

                        <article class="one-half">
                            <h6>{{ store.locale.contactUs }}</h6>
                            <p>{{ store.locale.contactUsBy }}:</p>
                            <p class="contact-data"><a v-bind:href="'tel:' + store.state.tenant.PhoneNumber">
                                <i class="icon bi bi-telephone"></i>
                                        {{ store.state.tenant.PhoneNumber}}</a></p>
                            <p class="contact-data"><a v-bind:href="'mailto:' + store.state.tenant.EmailAddress">
                                <i class="icon bi bi-envelope"></i>
                                {{ store.state.tenant.EmailAddress}}</a></p>
                            <p class="contact-data"><a :href="store.state.tenant.WebsiteURL" target="_blank">
                                <i class="icon bi bi-globe-europe-africa"></i> {{ store.locale.visitOurWebsite }}</a>
                            </p>
                        </article>

                    </div>

                    <div class="copy">

                        <p>&copy; 2012 - {{ new Date().getFullYear() }} - <a href="http://www.mijnfietsverhuur.nl"
                                title="mijnfietsverhuur.nl">mijnfietsverhuur.nl</a> </p>

                        <nav role="navigation" class="foot-nav">
                            <ul>
                                <li><a href="#" v-on:click="store.showTerms = true">{{ store.locale.terms }}</a></li>
                                <li>|</li>
                                <li><a href="#top" class="anchor animated" title="Back to top">Top</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </footer>
        </main>

        <mf-modal v-if="store.showTerms" @close="store.showTerms = false">
            <h1 slot="header" class="text-center">{{ store.locale.terms }}</h1>
            <div slot="body">
                <div class="row">
                    <div class="col-12" v-html="store.formatMarkDown(store.state.tenant.TermsAndConditions)">
                    </div>
                </div>

            </div>
        </mf-modal>

        <mf-modal v-if="store.state.showNoSupply" @close="store.state.showNoSupply = false">
            <h1 slot="header">Onvoldoende voorraad</h1>
            <div slot="body">

                <div v-if="store.state.noSupply.isFixedPriceProduct">
                    Helaas, {{ store.state.noSupply.title }} is niet meer beschikbaar.
                </div>

                <div v-else>
                    <div>
                        De {{ store.state.noSupply.title }} heeft onvoldoende voorraad voor de volledige periode.<br />
                        U kunt hieronder de actuele voorraad zien en eventueel uw huurperiode hierop afstemmen.
                    </div>
                    <div v-if="store.state.noSupply.isLoading">
                        <div class="article-loader sk-fading-circle">
                            <div class="sk-circle1 sk-circle"></div>
                            <div class="sk-circle2 sk-circle"></div>
                            <div class="sk-circle3 sk-circle"></div>
                            <div class="sk-circle4 sk-circle"></div>
                            <div class="sk-circle5 sk-circle"></div>
                            <div class="sk-circle6 sk-circle"></div>
                            <div class="sk-circle7 sk-circle"></div>
                            <div class="sk-circle8 sk-circle"></div>
                            <div class="sk-circle9 sk-circle"></div>
                            <div class="sk-circle10 sk-circle"></div>
                            <div class="sk-circle11 sk-circle"></div>
                            <div class="sk-circle12 sk-circle"></div>
                        </div>
                    </div>

                    <div class="mt-4 row" v-else>
                        <div v-for="(date, index) in store.state.noSupply.availabilityDates" :key="'supplydate-' + index"
                            class="col-xs-1 p-0 mr-1">
                            <div v-if="date.Available < store.state.noSupply.requestedAmount"
                                class="alert alert-danger text-center">
                                <div>
                                    {{ parseDate(date.Date).format("dddd") }}<br />
                                    {{ parseDate(date.Date).format("D/M") }}
                                    <br />
                                    <strong>{{ date.Available }}</strong>
                                </div>
                            </div>
                            <div v-else class="alert alert-success text-center">
                                <div>
                                    {{ parseDate(date.Date).format("dddd") }}<br />
                                    {{ parseDate(date.Date).format("D/M") }}
                                    <br />
                                    <strong>{{ date.Available }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </mf-modal>

    </div>
</template>

<script>
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import bootbox from 'bootbox';
import axios from 'axios';
import moment from 'moment';
import Header from '@/components/Header.vue';
import ReservationShop from '@/components/ReservationShop.vue';
import Modal from '@/components/Modal.vue';
import ShoppingCart from '@/components/ShoppingCart.vue';

let translations = [
    {
        locale: 'nl',
        pickupDate: 'Startdatum',
        pickupTime: 'Starttijd',
        pickupTimeCustom: 'Veerboot uit Lauwersoog',
        returnDate: 'Einddatum',
        backToSite: 'terug naar website',
        shoppingCart: 'Winkelwagen',
        shoppingCartEmpty: 'Leeg',
        locationName: 'Filiaal',
        pickupLocation: 'Afhaallocatie',
        onlineReservations: 'Online reserveren',
        about: 'Over',
        contactUs: 'Contact opnemen?',
        contactUsBy: 'Neem contact op via telefoon of e-mail',
        visitOurWebsite: 'Bezoek onze website',
        articleType: 'Productsoort',
        amount: 'Aantal',
        deliveryTitle: 'Bezorgen & ophalen',
        deliveryLabel: 'Ja, deze boeking graag bezorgen & ophalen',
        price: 'Prijs',
        close: 'Sluiten',
        backToTop: 'Terug naar boven',
        checkoutLabel: 'Checkout',
        itemsLabel: 'product(en)',
        date: 'Datum',
        chooseDate: 'Kies een datum',
        period: 'Periode',
        dayUnavailable: 'Het is niet mogelijk om op deze dag te reserveren.',
        amountAvailable: 'Aantal beschikbaar',
        addToBasket: 'Toevoegen aan winkelmand',
        blockRental: 'Korte verhuur',
        products: 'Producten',
        from: 'Van',
        untill: 'Tot',
        numberOfDays: 'Aantal dagen',
        suggestedForReservation: 'Aanbevolen bij uw reservering',
        subTotal: 'Subtotaal',
        reservationCosts: 'Reserveringskosten',
        discount: 'Korting',
        deliveryNotPossible: 'De volgende producten kunnen helaas niet worden bezorgd. Hierdoor is het niet mogelijk om bezorging voor deze boeking in te schakelen.',
        deposit: 'Borg',
        total: 'Totaal',
        amountToPay: 'Nu te voldoen',
        deliveryUnavailable: 'Vanwege de drukte is het bezorgen van fietsen helaas niet mogelijk',
        continueShopping: 'Verder winkelen',
        advanceToDetails: 'Uw gegevens invullen',
        reserveBlock: 'Reserveren',
        yourDetails: 'Uw gegevens',
        yourDetailsDescription: 'Vult u alstublieft onderstaande velden in. Controleer uw boekingsoverzicht en bij akkoord klikt u op "Door naar betalen".',
        salutation: 'Aanhef',
        streetSuffix: 'Toevoeging',
        streetNumber: 'Huisnummer',
        firstName: 'Voornaam',
        companyName: 'Bedrijfsnaam',
        companyVatNumber: 'BTW nummer',
        companyDetails: 'Bedrijfsgegevens (optioneel)',
        customerAddress: 'Persoonlijke gegevens',
        contactDetails: 'Contactgegevens',
        name: 'Achternaam',
        phoneNumber: 'Telefoonnummer',
        placeOfStay: '(Logeer)adres',
        placeOfStaySpecified: 'Gespecificeerd (kamer nr, appartement etc)',
        street: 'Straat',
        postalZipCode: 'Postcode',
        country: 'Land',
        city: 'Plaatsnaam',
        additionalAddress: {
            label: 'KOT- en studentgegevens',
            street: 'Straat',
            streetSuffix: 'Toevoeging',
            streetNumber: 'Huisnummer',
            postalZipCode: 'Postcode',
            city: 'Plaatsnaam',
            country: 'Land',
            phoneNumber: 'Telefoonnummer van ouders',
            firstName: 'Schoolnaam',
            name: 'Studentnummer',
        },
        emailAddress: 'E-mailadres',
        emailAddressConfirm: 'E-mailadres bevestigen',
        remark: 'Opmerking',
        acceptTerms: 'Ik ga akkoord met de',
        terms: 'Algemene voorwaarden',
        reservationRemark: '',
        userDetailDepositInfo: '',
        backToCart: 'Terug naar winkelwagen',
        advanceToBookingOverview: 'Door naar boekingsoverzicht',
        advanceToPayment: 'Door naar betalen',
        bookingOverview: 'Boekingsoverzicht',
        periodAbbreviation: 't/m',
        inclusiveComposedFromArticles: 'inclusief',
        warningArticlesUnAvailable: 'Helaas, binnen deze categorie zijn er geen artikelen beschikbaar om te reserveren.',
        defaultCountryCode: 'NLD',
        formValidation: {
            invalidName: 'Ongeldige naam opgegeven.',
            invalidPlaceOfStay: 'Logeer(adres) is verplicht.',
            invalidPhoneNumber: 'Ongeldig telefoonnummer opgegeven.',
            invalidEmailAddress: 'Ongeldig e-mailadres opgegeven.',
            invalidConfirmEmailAddress: 'Uw controle e-mailadres is onjuist.',
            termsNotAccepted: 'Uw dient eerst akkoord te gaan met onze voorwaarden',
            underliverableItemsInCart: 'U kunt niet alle geselecteerde producten op deze boeking laten bezorgen. U kunt de bezorgoptie uitzetten of de product(en) verwijderen dat niet bezorgd kan worden.'
        },
        coupon: {
            question: 'Heeft u een kortingscode?',
            validate: 'Valideren',
            yourcode: 'Uw kortingscode'
        }
    },
    {
        locale: 'de',
        pickupDate: 'Anfangsdatum',
        pickupTime: 'Anfangszeit',
        pickupTimeCustom: 'Fähre von Lauwersoog',
        returnDate: 'Endtermin',
        backToSite: 'zurück zur Webseite',
        shoppingCart: 'Einkaufswagen',
        shoppingCartEmpty: 'Leer',
        locationName: 'Ort',
        pickupLocation: 'Treffpunkt',
        checkoutLabel: 'Bezahlen',
        itemsLabel: 'produkt(e)',
        onlineReservations: 'Onlinereservierung',
        about: 'Über',
        backToTop: 'Nach oben',
        contactUs: 'Kontakt?',
        contactUsBy: 'Kontaktieren Sie uns per Telefon oder E-Mail',
        visitOurWebsite: 'Besuche unsere Webseite',
        articleType: 'Produktart',
        amount: 'Nummer',
        deliveryTitle: 'Lieferung & Abholung',
        deliveryLabel: 'Ja, bitte liefern und holen Sie diese Buchung ab',
        deliveryNotPossible: 'Die folgenden Produkte können leider nicht geliefert werden. Daher ist es nicht möglich, die Lieferung für diese Buchung zu aktivieren.',
        price: 'Preis',
        close: 'Schließen',
        date: 'Datum',
        chooseDate: 'Wählen Sie ein Datum aus',
        period: 'Zeitspanne',
        dayUnavailable: 'Eine Reservierung ist an diesem Tag nicht möglich.',
        amountAvailable: 'Verfügbare Menge',
        addToBasket: 'In den Warenkorb legen',
        blockRental: 'Kurzmiete',
        products: 'Produkte',
        from: 'Von',
        untill: 'Bis um',
        numberOfDays: 'Anzahl der Tage',
        suggestedForReservation: 'Empfohlen mit Ihrer Reservierung',
        subTotal: 'Zwischensumme',
        reservationCosts: 'Reservierungs-Gebühr',
        discount: 'Rabatt',
        deposit: 'Kaution',
        total: 'Gesamt',
        amountToPay: 'Jetzt erfüllt werden',
        continueShopping: 'Mit dem Einkaufen fortfahren',
        advanceToDetails: 'Gib meine Daten ein',
        reserveBlock: 'Reservieren',
        yourDetails: 'Deine Daten',
        yourDetailsDescription: 'Bitte füllen Sie die Felder unten aus. Überprüfen Sie Ihre Buchungsübersicht und klicken Sie, wenn Sie damit einverstanden sind, auf „Weiter zur Zahlung“.',
        salutation: 'Anrede',
        streetSuffix: 'Zusatz',
        streetNumber: 'Hausnummer',
        firstName: 'Vorname',
        companyName: 'Firmenname',
        companyVatNumber: 'Umsatzsteuer-Identifikationsnummer',
        companyDetails: 'Firmendetails (optional)',
        customerAddress: 'Persönliche Daten',
        contactDetails: 'Kontaktdetails',
        name: 'Nachname',
        phoneNumber: 'Telefonnummer',
        placeOfStay: '(Unterkunfts-)Adresse',
        placeOfStaySpecified: 'Angegeben (Zimmernummer, Wohnung etc.)',
        street: 'Straßenname',
        postalZipCode: 'Postleitzahl',
        country: 'Land',
        city: 'Ortsname',
        additionalAddress: {
            label: 'KOT- und Studentendaten',
            street: 'Straße',
            streetSuffix: 'Zusatz',
            streetNumber: 'Hausnummer',
            postalZipCode: 'Postleitzahl',
            city: 'Stadt',
            country: 'Land',
            phoneNumber: 'Telefonnummer der Eltern',
            firstName: 'Schulname',
            name: 'Studentennummer',
        },
        emailAddress: 'E-Mail-Addresse',
        emailAddressConfirm: 'Bestätigungs-E-Mail',
        remark: 'Bemerkungen',
        acceptTerms: 'Ich stimme dem zu',
        terms: 'Geschäftsbedingungen',
        reservationRemark: '',
        deliveryUnavailable: 'Infolge des hohen Aufkommens ist die Lieferung von Fahrrädern leider nicht möglich.',
        userDetailDepositInfo: '',
        backToCart: 'Zurück zum Warenkorb',
        advanceToBookingOverview: 'Weiter zur Buchungsübersicht',
        advanceToPayment: 'Weiter zur Zahlung',
        bookingOverview: 'Buchungsübersicht',
        periodAbbreviation: 'bis zu',
        inclusiveComposedFromArticles: 'inklusive',
        warningArticlesUnAvailable: 'Entschuldigung, in dieser Kategorie sind keine Artikel zum Reservieren verfügbar.',
        defaultCountryCode: 'DEU',
        formValidation: {
            invalidName: 'Ungültiger Name eingegeben.',
            invalidPhoneNumber: 'Ongeldig telefoonnummer opgegeven.',
            invalidPlaceOfStay: 'Logeer(adres) is verplicht!',
            invalidEmailAddress: 'Ungültige Email eingegeben.',
            invalidConfirmEmailAddress: 'Ihre Bestätigungs-E-Mail-Adresse ist falsch.',
            termsNotAccepted: 'Sie müssen zuerst unseren Allgemeinen Geschäftsbedingungen zustimmen.',
            underliverableItemsInCart: 'Sie können die ausgewählten Produkte in dieser Buchung nicht liefern lassen. Sie können die Lieferoption deaktivieren oder das Produkt entfernen, das nicht geliefert werden kann.'
        },
        coupon: {
            question: 'Haben Sie einen Rabattcode?',
            validate: 'Bestätigen',
            yourcode: 'Ihr Rabattcode'
        }
    },
    {
        locale: 'en',
        pickupDate: 'Start date',
        pickupTime: 'Start time',
        pickupTimeCustom: 'Ferry from Lauwersoog',
        returnDate: 'End date',
        checkoutLabel: 'Checkout',
        itemsLabel: 'item(s)',
        backToSite: 'back to website',
        backToTop: 'Back to top',
        shoppingCart: 'Shopping cart',
        shoppingCartEmpty: 'Empty',
        locationName: 'Location',
        pickupLocation: 'Pickup location',
        onlineReservations: 'Online reservations',
        about: 'About',
        contactUs: 'Contact us?',
        contactUsBy: 'Contact us by phone or email',
        visitOurWebsite: 'Visit our website',
        articleType: 'Product',
        amount: 'Amount',
        deliveryTitle: 'Delivery & pick up',
        deliveryUnavailable: 'Due to high demand, delivery of bicycles is unfortunately not possible.',
        deliveryLabel: 'Yes, please deliver & pick up this booking',
        deliveryNotPossible: 'The following products cannot be delivered. Therefore, it is not possible to enable delivery for this booking.',
        price: 'Price',
        close: 'Close',
        date: 'Date',
        chooseDate: 'Choose a date',
        period: 'Period',
        dayUnavailable: 'It is not possible to make a reservation on this day.',
        amountAvailable: 'Amount available',
        addToBasket: 'Add to cart',
        blockRental: 'Short rental',
        products: 'Products',
        from: 'From',
        untill: 'Untill',
        numberOfDays: 'Days',
        suggestedForReservation: 'Recommended with your reservation',
        subTotal: 'Subtotal',
        reservationCosts: 'Reservation fee',
        discount: 'Discount',
        deposit: 'Deposit',
        total: 'Total',
        amountToPay: 'To be fulfilled now',
        continueShopping: 'Continue shopping',
        advanceToDetails: 'My details',
        reserveBlock: 'To reserve',
        yourDetails: 'Your data',
        yourDetailsDescription: 'Please fill in the fields below. Check your booking overview and if you agree, click on "Proceed to payment".',
        salutation: 'Salutation',
        streetSuffix: 'Suffix',
        streetNumber: 'House Number',
        firstName: 'First Name',
        companyName: 'Company Name',
        companyVatNumber: 'VAT Number',
        companyDetails: 'Company Details (optional)',
        customerAddress: 'Personal Details',
        contactDetails: 'Contact Details',
        name: 'Surname',
        phoneNumber: 'Phonenumber',
        placeOfStay: '(Lodging) address',
        placeOfStaySpecified: 'Specified (room no, apartment etc)',
        street: 'Street Name',
        postalZipCode: 'Postal/Zipcode',
        city: 'City',
        additionalAddress: {
            label: 'KOT and Student Details',
            street: 'Street',
            streetSuffix: 'Suffix',
            streetNumber: 'House Number',
            postalZipCode: 'Postal Code',
            city: 'City',
            country: 'Country',
            phoneNumber: 'Parents\' Phone Number',
            firstName: 'School Name',
            name: 'Student Number',
        },
        country: 'Country',
        emailAddress: 'E-mailaddress',
        emailAddressConfirm: 'Confirm E-mailaddress',
        remark: 'Remarks',
        acceptTerms: 'I agree with the',
        terms: 'Terms and Conditions',
        reservationRemark: '',
        userDetailDepositInfo: '',
        backToCart: 'Back to shopping cart',
        advanceToBookingOverview: 'Continue to booking overview',
        advanceToPayment: 'Proceed to payment',
        bookingOverview: 'Booking overview',
        periodAbbreviation: 'up to',
        inclusiveComposedFromArticles: 'included',
        warningArticlesUnAvailable: 'Sorry, there are no items available to reserve within this category.',
        defaultCountryCode: '',
        formValidation: {
            invalidName: 'Invalid name entered.',
            invalidPhoneNumber: 'Invalid phone number provided.',
            invalidPlaceOfStay: 'Place of stay is required.',
            invalidEmailAddress: 'Invalid email address provided.',
            invalidConfirmEmailAddress: 'Your verification email address is incorrect.',
            termsNotAccepted: 'You must first agree to our terms and conditions.',
            underliverableItemsInCart: 'You cannot have the selected products delivered on this booking. You can either disable the delivery option or remove the product that cannot be delivered.'
        },
        coupon: {
            question: 'Do you have a discount code?',
            validate: 'Validate',
            yourcode: 'Your discount code'
        }
    },
    {
        "locale": "es",
        "pickupDate": "Fecha de recogida",
        "pickupTime": "Hora de recogida",
        "pickupTimeCustom": "Ferry desde Lauwersoog",
        "returnDate": "Fecha de devolución",
        "backToSite": "Volver al sitio web",
        "shoppingCart": "Carrito de compras",
        "shoppingCartEmpty": "Vacío",
        "locationName": "Sucursal",
        "pickupLocation": "Lugar de recogida",
        "onlineReservations": "Reservas en línea",
        "about": "Acerca de",
        "contactUs": "Contáctenos",
        "contactUsBy": "Contáctenos por teléfono o correo electrónico",
        "visitOurWebsite": "Visite nuestro sitio web",
        "articleType": "Tipo de producto",
        "amount": "Cantidad",
        "deliveryTitle": "Entrega y recogida",
        "deliveryLabel": "Sí, por favor entregar y recoger esta reserva",
        "price": "Precio",
        "close": "Cerrar",
        "backToTop": "Volver arriba",
        "checkoutLabel": "Pagar",
        "itemsLabel": "producto(s)",
        "date": "Fecha",
        "chooseDate": "Elija una fecha",
        "period": "Período",
        "dayUnavailable": "No es posible reservar en este día.",
        "amountAvailable": "Cantidad disponible",
        "addToBasket": "Agregar al carrito",
        "blockRental": "Alquiler a corto plazo",
        "products": "Productos",
        "from": "Desde",
        "untill": "Hasta",
        "numberOfDays": "Número de días",
        "suggestedForReservation": "Sugerido para su reserva",
        "subTotal": "Subtotal",
        "reservationCosts": "Costos de reserva",
        "discount": "Descuento",
        "deliveryNotPossible": "Los siguientes productos no se pueden entregar. Por lo tanto, no es posible habilitar la entrega para esta reserva.",
        "deposit": "Depósito",
        "total": "Total",
        "amountToPay": "Cantidad a pagar ahora",
        "deliveryUnavailable": "Debido a la alta demanda, lamentablemente no es posible la entrega de bicicletas.",
        "continueShopping": "Continuar comprando",
        "advanceToDetails": "Complete sus datos",
        "reserveBlock": "Reservar",
        "yourDetails": "Sus datos",
        "yourDetailsDescription": "Por favor, complete los siguientes campos. Revise el resumen de su reserva y, si está de acuerdo, haga clic en \"Continuar con el pago\".",
        salutation: 'Saludo',
        streetSuffix: 'Sufijo',
        streetNumber: 'Número de casa',
        firstName: 'Nombre',
        companyName: 'Nombre de la empresa',
        companyVatNumber: 'Número de IVA',
        companyDetails: 'Detalles de la empresa (opcional)',
        customerAddress: 'Datos personales',
        contactDetails: 'Datos de contacto',
        "name": "Apellido",
        "phoneNumber": "Número de teléfono",
        "placeOfStay": "Dirección (lugar de estadía)",
        "placeOfStaySpecified": "Especificado (número de habitación, apartamento, etc.)",
        "street": "Nombre de la calle",
        "postalZipCode": "Código postal",
        "city": "Ciudad",
        additionalAddress: {
            label: 'Datos de KOT y estudiante',
            street: 'Calle',
            streetSuffix: 'Complemento',
            streetNumber: 'Número de casa',
            postalZipCode: 'Código postal',
            city: 'Ciudad',
            country: 'País',
            phoneNumber: 'Número de teléfono de los padres',
            firstName: 'Nombre de la escuela',
            name: 'Número de estudiante',
        },
        "country": 'País',
        "emailAddress": "Dirección de correo electrónico",
        "emailAddressConfirm": "Confirmar correo electrónico",
        "remark": "Observación",
        "acceptTerms": "Acepto los",
        "terms": "Términos y condiciones",
        "reservationRemark": "",
        "userDetailDepositInfo": "",
        "backToCart": "Volver al carrito",
        "advanceToBookingOverview": "Continuar con el resumen de la reserva",
        "advanceToPayment": "Continuar con el pago",
        "bookingOverview": "Resumen de la reserva",
        "periodAbbreviation": "al",
        "inclusiveComposedFromArticles": "incluido(s)",
        "warningArticlesUnAvailable": "Lo sentimos, no hay artículos disponibles en esta categoría para reservar.",
        defaultCountryCode: "ESP",
        "formValidation": {
            "invalidName": "Nombre inválido.",
            "invalidPhoneNumber": "Número de teléfono inválido.",
            "invalidPlaceOfStay": 'Place of stay is required',
            "invalidEmailAddress": "Dirección de correo electrónico inválida.",
            "invalidConfirmEmailAddress": "La confirmación de su dirección de correo electrónico es incorrecta.",
            "termsNotAccepted": "Debe aceptar nuestros términos y condiciones.",
            "underliverableItemsInCart": "No puede hacer que se entreguen los productos seleccionados en esta reserva. Puede desactivar la opción de entrega o eliminar el producto que no se puede entregar."
        },
        coupon: {
            question: '¿Tiene un código de descuento?',
            validate: 'Validar',
            yourcode: 'Su código de descuento'
        }
    },
    {
        "locale": "fr",
        "pickupDate": "Date de début",
        "pickupTime": "Heure de début",
        "pickupTimeCustom": "Ferry depuis Lauwersoog",
        "returnDate": "Date de fin",
        "backToSite": "Retour au site",
        "shoppingCart": "Panier",
        "shoppingCartEmpty": "Vide",
        "locationName": "Filiale",
        "pickupLocation": "Point de collecte",
        "onlineReservations": "Réservation en ligne",
        "about": "À propos de nous",
        "contactUs": "Nous contacter",
        "contactUsBy": "Contactez-nous par téléphone ou par e-mail",
        "visitOurWebsite": "Visitez notre site web",
        "articleType": "Type de produit",
        "amount": "Quantité",
        "deliveryTitle": "Livraison et collecte",
        "deliveryLabel": "Oui, veuillez livrer et collecter cette réservation",
        "price": "Prix",
        "close": "Fermer",
        "backToTop": "Retour en haut",
        "checkoutLabel": "Paiement",
        "itemsLabel": "produit(s)",
        "date": "Date",
        "chooseDate": "Choisissez une date",
        "period": "Période",
        "dayUnavailable": "Il n'est pas possible de réserver ce jour-là.",
        "amountAvailable": "Quantité disponible",
        "addToBasket": "Ajouter au panier",
        "blockRental": "Location à court terme",
        "products": "Produits",
        "from": "De",
        "untill": "Jusqu'à",
        "numberOfDays": "Nombre de jours",
        "suggestedForReservation": "Recommandé pour votre réservation",
        "subTotal": "Sous-total",
        "reservationCosts": "Frais de réservation",
        "discount": "Réduction",
        "deliveryNotPossible": "Les produits suivants ne peuvent malheureusement pas être livrés. Par conséquent, il n'est pas possible d'activer la livraison pour cette réservation.",
        "deposit": "Caution",
        "total": "Total",
        "amountToPay": "Montant à payer",
        "deliveryUnavailable": "En raison d'une forte affluence, la livraison des vélos n'est malheureusement pas possible.",
        "continueShopping": "Continuer vos achats",
        "advanceToDetails": "Remplissez vos coordonnées",
        "reserveBlock": "Réserver",
        "yourDetails": "Vos coordonnées",
        "yourDetailsDescription": "Veuillez remplir les champs ci-dessous. Vérifiez votre récapitulatif de réservation et cliquez sur \"Passer au paiement\" si tout est correct.",
        salutation: 'Salutation',
        streetSuffix: 'Complément',
        streetNumber: 'Numéro de rue',
        firstName: 'Prénom',
        companyName: 'Nom de l\'entreprise',
        companyVatNumber: 'Numéro de TVA',
        companyDetails: 'Informations sur l\'entreprise (facultatif)',
        customerAddress: 'Données personnelles',
        contactDetails: 'Coordonnées',
        "name": "Nom de famille",
        "phoneNumber": "Numéro de téléphone",
        "placeOfStay": "Adresse (logement)",
        "placeOfStaySpecified": "Spécifié (numéro de chambre, appartement, etc.)",
        "street": "Nom de la rue",
        "postalZipCode": "Code postal",
        "city": "Ville",
        additionalAddress: {
            label: 'Informations sur le KOT et l\'étudiant',
            street: 'Rue',
            streetSuffix: 'Complément',
            streetNumber: 'Numéro de maison',
            postalZipCode: 'Code postal',
            city: 'Ville',
            country: 'Pays',
            phoneNumber: 'Numéro de téléphone des parents',
            firstName: 'Nom de l\'école',
            name: 'Numéro d\'étudiant',
        },
        "country": "Pays",
        "emailAddress": "Adresse e-mail",
        "emailAddressConfirm": "Confirmez votre adresse e-mail",
        "remark": "Remarque",
        "acceptTerms": "J'accepte les",
        "terms": "Conditions générales",
        "reservationRemark": "",
        "userDetailDepositInfo": "",
        "backToCart": "Retour au panier",
        "advanceToBookingOverview": "Accéder à l'aperçu de réservation",
        "advanceToPayment": "Payer",
        "bookingOverview": "Aperçu de réservation",
        "periodAbbreviation": "au",
        "inclusiveComposedFromArticles": "inclus",
        "warningArticlesUnAvailable": "Malheureusement, aucun article n'est disponible dans cette catégorie pour la réservation.",
        defaultCountryCode: "FRA",
        "formValidation": {
            "invalidName": "Nom invalide.",
            "invalidPhoneNumber": "Numéro de téléphone invalide.",
            "invalidPlaceOfStay": 'Place of stay is required',
            "invalidEmailAddress": "Adresse e-mail invalide.",
            "invalidConfirmEmailAddress": "Votre adresse e-mail de confirmation est incorrecte.",
            "termsNotAccepted": "Vous devez d'abord accepter nos conditions.",
            "underliverableItemsInCart": "Vous ne pouvez pas faire livrer les produits sélectionnés sur cette réservation. Vous pouvez désactiver l'option de livraison ou supprimer le produit qui ne peut pas être livré."
        },
        coupon: {
            question: 'Avez-vous un code de réduction?',
            validate: 'Valider',
            yourcode: 'Votre code de réduction'
        }
    },
    {
        "locale": "it",
        "pickupDate": "Data di ritiro",
        "pickupTime": "Orario di ritiro",
        "pickupTimeCustom": "Traghetto da Lauwersoog",
        "returnDate": "Data di restituzione",
        "backToSite": "Torna al sito",
        "shoppingCart": "Carrello",
        "shoppingCartEmpty": "Vuoto",
        "locationName": "Filiale",
        "pickupLocation": "Luogo di ritiro",
        "onlineReservations": "Prenotazioni online",
        "about": "Informazioni",
        "contactUs": "Contattaci",
        "contactUsBy": "Contattaci via telefono o e-mail",
        "visitOurWebsite": "Visita il nostro sito web",
        "articleType": "Tipo di prodotto",
        "amount": "Quantità",
        "deliveryTitle": "Consegna e ritiro",
        "deliveryLabel": "Sì, desidero la consegna e il ritiro di questa prenotazione",
        "price": "Prezzo",
        "close": "Chiudi",
        "backToTop": "Torna all'inizio",
        "checkoutLabel": "Checkout",
        "itemsLabel": "prodotto(i)",
        "date": "Data",
        "chooseDate": "Seleziona una data",
        "period": "Periodo",
        "dayUnavailable": "Non è possibile prenotare in questo giorno.",
        "amountAvailable": "Quantità disponibile",
        "addToBasket": "Aggiungi al carrello",
        "blockRental": "Noleggio breve",
        "products": "Prodotti",
        "from": "Da",
        "untill": "Fino a",
        "numberOfDays": "Numero di giorni",
        "suggestedForReservation": "Consigliato per la tua prenotazione",
        "subTotal": "Subtotale",
        "reservationCosts": "Costi di prenotazione",
        "discount": "Sconto",
        "deliveryNotPossible": "I seguenti prodotti non possono essere consegnati. Pertanto, non è possibile attivare la consegna per questa prenotazione.",
        "deposit": "Caparra",
        "total": "Totale",
        "amountToPay": "Importo da pagare",
        "deliveryUnavailable": "A causa dell'alta affluenza, la consegna delle biciclette non è purtroppo possibile.",
        "continueShopping": "Continua lo shopping",
        "advanceToDetails": "Compila i tuoi dati",
        "reserveBlock": "Prenota",
        "yourDetails": "I tuoi dati",
        "yourDetailsDescription": "Compila i campi sottostanti. Controlla il riepilogo della prenotazione e, se sei d'accordo, clicca su \"Procedi al pagamento\".",
        salutation: 'Saluto',
        streetSuffix: 'Suffisso',
        streetNumber: 'Numero civico',
        firstName: 'Nome',
        companyName: 'Nome dell \'azienda',
        companyVatNumber: 'Partita IVA',
        companyDetails: 'Dettagli aziendali (opzionale)',
        customerAddress: 'Dati personali',
        contactDetails: 'Dettagli di contatto',
        "name": "Cognome",
        "phoneNumber": "Numero di telefono",
        "placeOfStay": "Indirizzo di soggiorno",
        "placeOfStaySpecified": "Specificato (n. stanza, appartamento, ecc.)",
        "street": "Nome della strada",
        "postalZipCode": "Codice postale",
        "city": "Città",
        additionalAddress: {
            label: 'Dati KOT e dello studente',
            street: 'Via',
            streetSuffix: 'Suffisso',
            streetNumber: 'Numero civico',
            postalZipCode: 'CAP',
            city: 'Città',
            country: 'Paese',
            phoneNumber: 'Numero di telefono dei genitori',
            firstName: 'Nome della scuola',
            name: 'Numero studente',
        },
        "country": "Paese",
        "emailAddress": "Indirizzo e-mail",
        "emailAddressConfirm": "Conferma l'indirizzo e-mail",
        "remark": "Osservazione",
        "acceptTerms": "Accetto i",
        "terms": "Termini e condizioni",
        "reservationRemark": "",
        "userDetailDepositInfo": "",
        "backToCart": "Torna al carrello",
        "advanceToBookingOverview": "Procedi alla panoramica della prenotazione",
        "advanceToPayment": "Procedi al pagamento",
        "bookingOverview": "Panoramica della prenotazione",
        "periodAbbreviation": "al",
        "inclusiveComposedFromArticles": "incluso(i)",
        "warningArticlesUnAvailable": "Sfortunatamente, in questa categoria non ci sono articoli disponibili per la prenotazione.",
        defaultCountryCode: "ITA",
        "formValidation": {
            "invalidName": "Nome non valido.",
            "invalidPhoneNumber": "Numero di telefono non valido.",
            "invalidPlaceOfStay": 'Place of stay is required',
            "invalidEmailAddress": "Indirizzo e-mail non valido.",
            "invalidConfirmEmailAddress": "Il tuo indirizzo e-mail di conferma non è corretto.",
            "termsNotAccepted": "È necessario accettare i nostri termini e condizioni.",
            "underliverableItemsInCart": "Non è possibile far consegnare i prodotti selezionati in questa prenotazione. Può disattivare l'opzione di consegna oppure rimuovere il prodotto che non può essere consegnato."
        },
        coupon: {
            question: 'Hai un codice sconto?',
            validate: 'Convalidare',
            yourcode: 'Il tuo codice sconto'
        }
    }
];

var store = {
    debug: false,
    locale: translations[0],
    showTerms: false,
    state: {
        categories: [],
        workingHours: [],
        accommodations: [],
        timesuggestions: [],
        notification: null,
        locations: [],
        tenant: {},
        cart: {
            articles: [],
            blocks: [],
            pricing: {
                subTotal: 0,
                discount: 0,
                reservationCosts: 0,
                extraDiscount: 0,
                deposit: 0,
                SafeplanTotal: 0
            }
        },
        hasDeliveryAvailableOnDay: false,
        hasDiscountAvailableOnDay: true,
        selectedLocationLoaded: false,
        selectedLocation: {},
        selectedCategory: {},
        departureDate: null,
        returnDate: null,
        showNoSupply: false,
        noSupply: {
            availabilityDates: [],
            title: "",
            requestedAmount: 0,
            isLoading: false
        }
    },

    changeTermsToggle(value) {
        this.state.showTerms = value;
    },
    defaultPlaceOfStayDescription() {
        if (this.numberOfDays() == 1) {
            return this.state.tenant.DefaultSingleDayAccommodation;
        }
        return "";
    },

    getStartDayMessage(date) {

        let message = this.getReservationRemarkForDay(date);
        if (this.getWorkingHourSetOnDay(date).length === 0) {
            message = this.state.tenant.DepartureDayClosedMessage;
        }

        return message;
    },

    formatMarkDown(val) {
        if (val == undefined || val == null) {
            return "";
        }
        return DOMPurify.sanitize(marked(val));
    },

    // get a remark for the date (if any)
    getReservationRemarkForDay(date) {

        let workingHoursSet = this.getWorkingHourSetOnDay(date);

        if (workingHoursSet.length > 0) {

            let departureTimeRemark = workingHoursSet[0];

            if (departureTimeRemark != undefined) {
                return departureTimeRemark.Remark ? departureTimeRemark.Remark : "";
            }
        }
        return "";
    },

    getWorkingHourSetOnDay(date) {

        if (date == null || !date.isValid() || !this.state.workinghours) {
            return [];
        }

        let workingHoursSet = this.state.workinghours.filter(x => moment(x.DateFrom, "YYYY-MM-DD").isSameOrBefore(moment(date).format("YYYY-MM-DD")));
        const results = workingHoursSet[workingHoursSet.length - 1].Schedule.filter(x => x.DayOfWeek === date.day()).sort(function (a, b) {
            return parseInt(a.StartTime.replace(":", "")) - parseInt(b.StartTime.replace(":", ""))
        });
        return results;
    },

    getArticlePrice(article) {
        return article.LinePriceTotal;
    },
    getTotalSafeplan(article) {
        return article.LineSafeplanTotal;
    },

    getReturnDayMessage(date) {
        let message = this.getReservationRemarkForDay(date);

        if (this.getWorkingHourSetOnDay(date).length === 0) {
            message = this.state.tenant.ReturnDayClosedMessage;
        }

        return message;
    },

    getNotificationTitle(notification) {

        let title = notification.Translations.filter(x => x.ISO == 'nl')[0].Title;

        if (this.locale.locale != 'nl') {
            let val = this.locale.locale == 'en' ? 'uk' : this.locale.locale;
            let translation = notification.Translations.filter(x => x.ISO == val);
            title = translation.length == 1 && translation[0].Title !== '' ? translation[0].Title : title;
        }


        return title;
    },

    getNotificationMessage(notification) {

        let decription = notification.Translations.filter(x => x.ISO == 'nl')[0].Description;

        if (this.locale.locale != 'nl') {
            let val = this.locale.locale == 'en' ? 'uk' : this.locale.locale;
            let translation = notification.Translations.filter(x => x.ISO == val);
            decription = translation.length == 1 && translation[0].Description !== '' ? translation[0].Description : decription;
        }
        return decription;
    },


    getArticleTitle(item) {

        let title = item.Title;

        if (!item.Languages)
            return item.Title;

        if (this.locale.locale != 'nl') {
            let val = this.locale.locale == 'en' ? 'uk' : this.locale.locale;
            let translation = item.Languages.filter(x => x.ISO == val);
            title = translation.length == 1 ? translation[0].Title : item.Title;
        }

        return title.length == 0 ? item.Title : title;

    },

    getArticleDescription(item) {
        let description = item.Description;

        if (!item.Languages)
            return description;

        if (this.locale.locale != 'nl') {
            let val = this.locale.locale == 'en' ? 'uk' : this.locale.locale;
            let translation = item.Languages.filter(x => x.ISO == val);
            description = translation.length == 1 ? translation[0].Description : item.Description;
        }

        return description.length == 0 ? item.Description : description;
    },

    numberOfArticles() {
        let articles = this.state.cart.articles;
        let articleCount = (articles || []).reduce(function (r, a) {
            return r + (a.Amount || 0);
        }, 0);

        let blockCount = (this.state.cart.blocks || []).reduce(function (r, a) {
            return r + (a.Amount || 0);
        }, 0);
        return articleCount + blockCount;
    },

    numberOfDays() {
        if (this.state.returnDate)
            return this.state.returnDate.diff(this.state.departureDate, 'days') + 1;
        return 1;
    },
    setLocale(locale) {
        this.locale = translations.filter(x => x.locale === locale)[0];
    },
    dispatch(method, payload) {
        switch (method) {
            case 'updatecart':
                this.updateCart(payload);
                break;
            case 'setlocation':
                this.setLocation(payload);
                break;
        }
    },
    calculateArticleTotal(article) {
        this.state.departureDate = moment(this.state.departureDate);
        this.state.returnDate = moment(this.state.returnDate);
        if (this.state.departureDate == null || this.state.returnDate == null) {
            //bootbox.alert("@Resources.MsgSelectPeriod");
            article.Amount = 0;
            this.$nextTick(() => {
                this.$refs.depDate.focus();
            });
            return;
        }
        let _departureDate = moment(this.state.departureDate).clone();
        let _returnDate = moment(this.state.returnDate).clone();

        var that = this;
        var maxRentOutDuration = article.MaxRentOutDuration;
        var minRentOutDuration = article.MinRentOutDuration;
        if (maxRentOutDuration == 0) {
            maxRentOutDuration = 99999999;
        }
        if (minRentOutDuration == 999999999) {
            minRentOutDuration = 0;
        }


        var duration = moment.duration(_returnDate.diff(_departureDate));
        var hours = duration.asHours();

        if (hours > maxRentOutDuration) {
            let maxDuration = maxRentOutDuration + " @Resources.lblHour";
            if (maxRentOutDuration > 8) {
                let days = Math.floor(maxRentOutDuration / 24) + 1;
                maxDuration = days + "  @Resources.lblDay (en)"
            }

            bootbox.alert(this.getArticleTitle(article) + " @Resources.lblHasMaximumRentalPeriodOf " + maxDuration);
            article.Amount = 0;
            return false;
        }

        let numberOfDays = moment.duration(_returnDate.startOf('day').diff(_departureDate.startOf('day'))).asDays() + 1;
        let minimalNumberOfDays = Math.floor(minRentOutDuration / 24);


        if (numberOfDays < minimalNumberOfDays) {

            let maxDuration = minRentOutDuration + " @Resources.lblHour";
            if (minRentOutDuration > 8) {
                let days = Math.floor(minRentOutDuration / 24);
                maxDuration = days + " @Resources.lblDay (en)"
            }

            bootbox.alert(this.getArticleTitle(article) + " @Resources.lblHasMinimalRentalPeriodOf " + maxDuration);
            article.Amount = 0;
            return false;
        }


        that = this;
        axios.get(`/api/article/${article.Id}`, {
            params: {
                startDate: this.state.departureDate.format("YYYY-MM-DDTHH:mm"),
                returnDate: this.state.returnDate.format("YYYY-MM-DDTHH:mm"),
                locationId: store.state.selectedLocation.Id
            }
        }).then(availabilityResponse => {
            if (Number(article.Amount) > Number(availabilityResponse.data.Amount)) {
                store.state.noSupply.requestedAmount = article.Amount;
                store.state.noSupply.title = article.Title;
                store.state.noSupply.isFixedPriceProduct = article.FixedPrice > 0;
                store.state.noSupply.isLoading = true;
                store.state.showNoSupply = true;
                article.Amount = availabilityResponse.data.Amount;

                axios.get(`/api/article/${article.Id}/availability/dates`, {
                    params: {
                        startDate: store.state.departureDate.format("YYYY-MM-DDTHH:mm"),
                        returnDate: store.state.returnDate.format("YYYY-MM-DDTHH:mm"),
                        locationId: store.state.selectedLocation.Id
                    }
                }).then(response => {
                    store.state.noSupply.availabilityDates = response.data;
                    store.state.noSupply.isLoading = false;
                });
            }

            article.LineDiscount = availabilityResponse.data.Discount * article.Amount;
            article.LinePriceTotal = (availabilityResponse.data.PriceTotal * article.Amount) - article.LineDiscount;
            article.LineSafeplanTotal = availabilityResponse.data.SafeplanTotal;
            article.LinePriceTotalFormatted = article.LinePriceTotal.toCurrencyString();
            that.state.hasDeliveryAvailableOnDay = availabilityResponse.data.DeliveryAvailable;
            that.state.hasDiscountAvailableOnDay = availabilityResponse.data.IsDiscountEnabled;
            //console.log(availabilityResponse.data.SafeplanTotal);
            store.updateCart();
        });
    },
    recalculateAll() {
        this.getArticlesInCart().map(x => this.calculateArticleTotal(x));
    },
    getArticlesInCart() {
        let _articles = [];
        this.state.categories.map(category => {
            if (category.Articles == null) return;
            let articlesFromCategory = category.Articles.filter(article => article.Amount > 0);
            articlesFromCategory.map(y => _articles.push(y));
        });


        return _articles.filter(article => !this.state.selectedLocation.BlockedArticles.includes(article.Id));

    },
    getBlocksInCart() {
        return this.state.cart.blocks;
    },
    getUnDeliverableItemsInCart() {
        return [...this.getArticlesInCart().filter(x => !x.DeliveryAvailable), ...this.getBlocksInCart().filter(x => !x.DeliveryAvailable)];
    },
    getRelatedArticlesCart(cart) {
        let _articles = [];
        cart.map(article => {
            let relatedArticles = article.Related.filter(related => related.Amount > 0);
            relatedArticles.map(y => _articles.push(y));
        });
        return _articles;
    },
    gotoTop() {
        let top = document.getElementById("top").offsetTop;
        window.scrollTo(0, top);
    },
    updateCart() {
        if (this.debug) console.log('updateCart triggered');
        let that = this;


        let DEPOSITMODE_SUM_ALL = "SUM_ALL"; // sum all article * amount
        let DEPOSITMODE_PER_ORDER = "PER_ORDER"; // highest deposit only

        let depositMode = this.state.tenant.DepositMode;

        let articlesInCart = this.getArticlesInCart();
        let relatedArticlesInCart = this.getRelatedArticlesCart(articlesInCart);
        let blocksInCarts = this.getBlocksInCart();

        let articlesSubTotal = (articlesInCart || []).reduce(function (r, a) {
            return r + that.getArticlePrice(a);
        }, 0);

        let articlesSafeplanTotal = (articlesInCart || []).reduce(function (r, a) {
            return r + that.getTotalSafeplan(a);
        }, 0);

        let relatedArticlesSubTotal = (relatedArticlesInCart || []).reduce(function (r, a) {
            return r + that.getArticlePrice(a);
        }, 0);

        let blockSubTotal = (blocksInCarts || []).reduce(function (r, a) {
            return r + (a.Price * a.Amount || 0);
        }, 0);

        let depositTotal = 0;

        if (depositMode == DEPOSITMODE_SUM_ALL) { // sum all article * amount
            let articlesDeposit = (articlesInCart || []).reduce(function (r, a) {
                return r + (a.Deposit * a.Amount || 0);
            }, 0);

            let relatedArticlesSubTotal = (relatedArticlesInCart || []).reduce(function (r, a) {
                return r + (a.Deposit * a.Amount || 0);
            }, 0);

            depositTotal = articlesDeposit + relatedArticlesSubTotal;
        }

        if (depositMode == DEPOSITMODE_PER_ORDER) { // highest deposit only

            let currentDeposit = 0;
            articlesInCart.map(x => {
                if (x.Deposit > currentDeposit) {
                    currentDeposit = x.Deposit;
                }
            });

            relatedArticlesInCart.map(x => {
                if (x.Deposit > currentDeposit) {
                    currentDeposit = x.Deposit;
                }
            });

            depositTotal = currentDeposit;
        }

        this.state.cart = {
            articles: articlesInCart,
            relatedArticles: relatedArticlesInCart,
            blocks: blocksInCarts,
            pricing: {
                subTotal: articlesSubTotal + relatedArticlesSubTotal + blockSubTotal,
                deposit: depositTotal,
                reservationCosts: Number(this.state.tenant.WebsiteReservationCosts),
                discount: 0,
                SafeplanTotal: articlesSafeplanTotal
            }
        };

        this.saveState();
    },

    saveState() {
        const now = new Date();
        const expirationDate = now.getTime() + 10 * 60 * 1000;


        const item = {
            value: this.state,
            expiry: expirationDate,
        };

        localStorage.setItem("state", JSON.stringify(item));
    },
    setLocation(value, cb) {
        this.state.selectedLocation = value;
        this.selectedLocationLoaded = false;

        let referenceTable = "BranchLocation";

        if (isNaN(value.Id)) {
            referenceTable = "Partner";
        }

        axios.post(`/api/location/${value.Id}/workinghours`, { ReferenceTable: referenceTable })
            .then((result) => {
            this.state.workinghours = result.data;
            const workingHoursSorted = this.state.workinghours.sort((a, b) => moment(a.DateFrom, 'YYYY-MM-DD').diff(moment(b.DateFrom, 'YYYY-MM-DD')));

            const getDatesOfDayOfWeekInPeriod = (start, end, dayOfWeek) => {
                var result = [];
                var current = start.clone();

                while (current.isSameOrBefore(end)) {
                if (current.day() === dayOfWeek) {
                    result.push(current.clone().format('YYYY-MM-DDT00:00'));
                }
                current.add(1, 'day');
                }

                return result;
            };

            let blockedWorkingHours = [];
            workingHoursSorted.map(w => {

                let currentScheduleStart = moment(w.DateFrom, 'YYYY-MM-DD').startOf('day');
                let nextScheduleStart = workingHoursSorted.filter(x => moment(x.DateFrom, 'YYYY-MM-DD').isAfter(currentScheduleStart));

                let checkStartDay = moment();

                if (currentScheduleStart.isBefore(checkStartDay)) {
                currentScheduleStart = checkStartDay;
                }

                let checkUntillDay = currentScheduleStart.clone().add(10, 'months');
                nextScheduleStart = nextScheduleStart.length > 0 ? moment(nextScheduleStart[0].DateFrom, 'YYYY-MM-DD').startOf('day') : checkUntillDay;

                if (nextScheduleStart.isAfter(checkUntillDay)) {
                nextScheduleStart = checkUntillDay;
                }

                for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                const schedule = w.Schedule.filter(y => y.DayOfWeek == dayOfWeek);
                if (schedule.length == 0) {
                    // no day in this period, so closed
                    // fetch all dates from the start of this period untill the start of the next period
                    let days = getDatesOfDayOfWeekInPeriod(currentScheduleStart.add(-1, 'days'), nextScheduleStart, dayOfWeek);
                    blockedWorkingHours.push(days);
                }
                }

            });
            let originalBlocked = [...this.state.selectedLocation.BlockedDates];
            this.state.selectedLocation.BlockedDates = []
            this.state.selectedLocation.BlockedDates = originalBlocked.concat(...blockedWorkingHours);
            })
            .then(() => {
            this.recalculateAll();
            if (cb) cb();
            });

        //this.updateCart();
    },
    setPeriod(departureDate, returnDate) {
        //console.log('setPeriod triggered with', departureDate, returnDate);
        this.state.departureDate = departureDate;
        this.state.returnDate = returnDate;
        this.recalculateAll();
    },
    setSelectedCategory(value) {
        if (this.debug) console.log('setSelectedCategory triggered with', value);
        this.state.selectedCategory = value;
    }
}

export default {
    data: function () {
        return {
            isLoading: false,
            showShoppingCart: false,
            isLocationLoaded: false,
            shoppingCart: {
                articles: []
            },
            store: store,
        }
    },
    components: {
        'mf-header': Header,
        'mf-reservation-shop': ReservationShop,
        'mf-modal': Modal,
        'mf-shoppingcart': ShoppingCart,
    },
    created: function () {
        this.initialize();
    },
    methods: {
        initialize() {

            this.isLoading = true;
            axios.defaults.headers.common['Accept-Language'] = 'nl-NL';

            var previousState = localStorage.getItem("state");
            if (previousState) {

                const item = JSON.parse(previousState);
                const now = new Date();

                if (now.getTime() > item.expiry) {
                    localStorage.removeItem("state");
                } else {
                    this.store.state = item.value;
                }
            }

            if (this.store.state.locations.length == 0) {
                axios.get("/api/account")
                    .then(response => {
                        const data = response.data;
                        this.store.state.categories = data.ArticleCategories;
                        this.store.state.accommodations = data.Accommodations;
                        this.store.state.timesuggestions = data.TimeSuggestions;
                        this.store.state.locations = data.Locations;
                        this.store.state.tenant = data.Data;
                        this.store.state.notification = data.Notification;
                        this.isLoading = false;

                        this.store.setLocation(this.store.state.locations[0], () => this.isLocationLoaded = true);

                        document.title = this.store.state.tenant.Name;
                        this.store.saveState();
                    })
                    .catch(error => {
                        console.error("There was an error fetching the account data:", error);
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
                this.store.setLocation(this.store.state.locations[0], () => this.isLocationLoaded = true);
                document.title = this.store.state.tenant.Name;
            }



        },
        onSelectedArticlesChanged(shoppingCart) {
            this.shoppingCart = shoppingCart;
        },
        viewCartClicked() {
            this.store.gotoTop();
            this.showShoppingCart = true;
        },
        closeShoppingCart() {
            this.store.gotoTop();
            this.showShoppingCart = false;
        },
        parseDate(dt) {
            return moment(dt);
        }
    }
};
</script>
