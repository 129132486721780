@using Resources;

<template id="mf-reservationrequest-page">

    <div>

        <main class="main" role="main">

            <a href="#top" class="anchor"></a>

            <div>



                <div class="mt-3">
                    <div class="container">

                        <div class="sub-header">
                            <div class="wrap">
                                <a href="#"></a>
                                <a href="#" v-on:click="setLocale('de')"><img src="@/assets/flags/de.png"></a>
                                <a href="#" v-on:click="setLocale('en')"><img src="@/assets/flags/gb.png"></a>
                                <a href="#" v-on:click="setLocale('nl')"><img src="@/assets/flags/nl.png"></a>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-12">

                                <header class="header sticky" role="banner">
                                    <div class="wrap">
                                        <a class="logo" href="/" id="top">&nbsp;</a>
                                        <nav role="navigation" class="main-nav">
                                            <ul>
                                                <li>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </header>

                                <section class="site-title color no-margin">
                                    <div class="text-center">
                                        <h1 style="color: #ffffff">
                                            {{ locale.paymentRequest }}
                                        </h1>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-center">

                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-12">

                                                    <mf-spinner v-if="isLoading"></mf-spinner>

                                                    <div v-else>
                                                        <strong>{{ locale.number }}:</strong> {{ number }}<br />


                                                        <div v-if="amount > 0">
                                                            <strong>{{ locale.amount }}:</strong> &euro; {{ amount
                                                            }}<br />
                                                        </div>

                                                        <div v-if="deposit > 0">
                                                            <strong>{{ locale.deposit }}:</strong> &euro; {{ deposit
                                                            }}<br />
                                                        </div>

                                                        <p>&nbsp;</p>
                                                        <button v-on:click="startPayment" class="btn color">{{
                                                            locale.payNow }}</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    </div>


</template>

<script>
import axios from 'axios';
let translations = [
    {
        locale: 'nl',
        number: "Ordernummer",
        amount: "Bedrag",
        deposit: "Borg",
        payNow: "Direct betalen",
        paymentRequest: "Betaalverzoek",
        requestExpired: "Dit verzoek is al verlopen of voldaan."
    },
    {
        locale: 'de',
        number: "Bestellnummer",
        amount: "Betrag",
        deposit: "Kaution",
        payNow: "Jetzt bezahlen",
        paymentRequest: "Zahlungsanfrage",
        requestExpired: "Diese Anfrage ist bereits abgelaufen oder wurde erfüllt."
    },
    {
        locale: 'en',
        number: "Ordernumber",
        amount: "Amount",
        deposit: "Deposit",
        payNow: "Pay now",
        paymentRequest: "Paymentrequest",
        requestExpired: "This request has already expired or has been fulfilled."
    },
];

export default {
     data: function () {
        return {
            token: '',
            id: '',
            isLoading: false,
            translations: translations,
            locale: null,
            tenant: null,
            amount: 0,
            deposit: 0,
            number: 0
        }
    },
    created: function () {

        this.id = window.location.href.split('/')[4];
        this.token = window.location.href.split('/')[5];

        this.locale = this.translations[0];
        this.initialize();
    },
    methods: {
        initialize() {
            this.isLoading = true;
            axios.get("/api/account")
                .then(response => {
                    this.tenant = response.data.Data;
                    document.title = this.tenant.Name;

                    return axios.get(`/FetchPaymentRequest/${this.id}/${this.token}`);
                })
                .then(response => {
                    this.isLoading = false;
                    this.number = this.id;
                    this.amount = response.data.Amount;
                    this.deposit = response.data.Deposit;
                })
                .catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });


        },
        setLocale(val) {
            this.locale = this.translations.filter(x => x.locale == val)[0];
        },
        startPayment() {
            this.isLoading = true;
            axios.post(`/StartPaymentRequest/${this.id}/${this.token}`)
                .then(response => {
                    if (response.data === 'ALREADY_PAID_OR_EXPIRED') {
                       // bootbox.alert(this.locale.requestExpired);
                        this.isLoading = false;
                    } else {
                        window.location.href = response.data;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
        }
    }
};
</script>
